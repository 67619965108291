html {
  scroll-behavior: smooth;
}
.sh3-body {
    background: #fff;
    color: #6b7385;
    font-family: Quicksand,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.sh3-header {
    padding: 10px 0px;
    border-bottom: 1px solid #EAEDF0;
    background: #fff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 0.5s ease;
}
.sh3-header-nav {
    padding: 0px;
}
.sh3-logo {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.sh3-logo #site_logo {
  max-height: 125px;
}
ul.sh3-header-link {
    display: flex;
    height: 100%;
    align-items: center;
}
ul.sh3-header-link li {
    display: inline-block;
    padding: 0 6px;
}
.add-address-form .btn {
  background: var(--primary-color);
}
.sh3-header-link li a {
    font-size: 18px;
    font-weight: 700;
    color: var(--nav-color);
    text-decoration: none;
    text-decoration: none;
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -o-transition: .5s all;
    -ms-transition: .5s all;
    display: block;
}
.sh3-header-link li .nav-bar-link.active {
    color: var(--nav-color-active);
    position: relative;
  }
  .sh3-header-link li .nav-bar-link.active::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    background: var(--nav-color-active);
    border-radius: 50%;
    left: -10px;
    top: 9px;
  }
.sh3-header-link li a:hover {
    color: var(--nav-color-hover);
    text-decoration: none;
}
.sh3-header-link-right {
    margin-left: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .sh3-header-link-right li {
    display: inline-block;
    font-weight: 500;
  }
.sh3-header-link-right li:not(:first-child) {
    margin-left: 12px;
  }
  .sh3-header-link-right img {
    margin-right: 5px;
  }
  .sh3-header-auth a {
    color: var(--primary-color);
    border: 2px solid #1B9DB3;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
  }
  .sh3-header-auth a i {
    margin-right: 8px;
  }
  .sh3-header-link-right .title-btn i {
    margin-right: 8px;
  }
.sh3-count-blk {
    display: block;
    font-weight: 600;
    font-size: 16px;
  }
  .sh3-header-cart-box {
    display: flex;
    align-items: center;
    padding: 5px;
    background: #fff;
    box-shadow: 0px 4px 44px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
  }
  .sh3-header-cart-box i {
    background: var(--secondary-color);
    color: #fff;
    padding: 0 10px;
    margin-right: 10px;
    border-radius: 5px 0 0 5px;
    font-size: 26px;
  }
  .sh3-cart-box-info {
    padding: 0 15px 0 0;
    font-size: 14px;
    line-height: normal;
  }
  .sh3-home_single_search {
    width: 100%;
    /* background: url("../../../assets/img/home_page_single_search.jpg") no-repeat top center; */
    position: relative;
    z-index: 1;
    padding: 40px 0;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.sh3-home_single_search h1 {
    font-size: 60px;
    font-weight: 700;
    margin: 15px 0;
}
.formonlybtn .sh3-postcodeform {
  padding: 0;
  background: transparent;
  border: 0;
}
.formonlybtn input[type="text"] {
    display: none;
}
.sh3-postcodeform {
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 44px rgba(197, 197, 197, 0.25);
}
.sh3-postcodeform input[type=text] {
    padding: 10px 15px;
    border: 0;
    width: 100%;
    font-weight: 700;
    outline: 0;
}
.sh3-postcodeform input[type=submit],.sh3-postcodeform .btn {
    padding: 15px;
    font-size: 16px;
    border: 0;
    background-color: var(--primary-color);
    flex: 0 0 auto;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
}
.sh3-postcodeform .btn a{
    text-decoration: none;
    color: #fff;
}
.sh3-header-link li .sh3-cart-btn {
    align-items: center;
    display: inline-flex;
    padding: 0;
    background-color: #fff;
    transition: 250ms;
    color: #000;
    border-radius: 50px;
    width: 48px;
    justify-content: center;
    height: 48px;
    position: relative;
}
.sh3-cart-list {
    position: relative;
}
.login-link a > .color-theme {
    color: #fff !important;
    font-weight: 800;
    text-decoration: none;
}
.sh3-banner-btn-group {
  margin: 30px 0 60px;
}
.sh3-banner-btn-group .btn:not(:last-child),.sh3-banner-app li:not(:last-child) {
  margin-right: 15px;
}
.sh3-banner-app-links li {
  display: inline-block;
}
.sh3-banner-app h4 {
  margin-bottom: 20px;
}
.sh3-banner-search-wrap, .sh3-heroimg {
  position: relative;
  z-index: 2;
}
.sh3-banner-tag {
    background: #EAEDF0;
    display: inline-block;
    padding: 3px 20px;
    font-weight: 600;
    color: #192335;
    border-radius: 20px;
  }
  .sh3-homesearch {
    padding: 80px 0;
  }
  .sh3-priority-section {
    padding: 50px 0;
    background: #F8F8F8;
  }
  .sh3-priority-card {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 44px rgba(197, 197, 197, 0.25);
    margin-bottom: 24px;
    transition: all 0.5s;
    height: 95%;
  }
  .sh3-priority-card:hover {
    background: var(--primary-color);
  }
  .sh3-priority-card:hover *{
    color: #fff;
  }
  .sh3-priority-card h3 {
    margin: 20px 0 15px;
  }
  .sh3-priority-card-icon {
    display: flex;
    margin: 0 auto;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    border-radius: 5px;
  }
  .sh3-load-more p {
    display: inline-block;
    color: #fff;
  }
  .sh3-load-more {
    margin-top: 16px;
    text-align: center;
  }  
  .sh3-home-offer-close {
    position: absolute;
    right: 15px;
    color: #fff;
    font-size: 20px;
  }
.sh3-priority-img img {
    border-radius: 8px;
    border-radius: 8px;
-webkit-transition: ease all 0.5s;
-ms-transition: ease all 0.5s;
transition: ease all 0.5s;
  }
  .sh3-priority-img {
    overflow: hidden;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    transition: ease all 0.5s;
  }
  .sh3-priority-img img:hover {
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    transition: ease all 0.5s;
    transform: scale(1.1) rotate(3deg);
    -webkit-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
  }
  .sh3-section-title {
    text-align: center;
    margin: 0 0 40px;
  }
.sh3-section-title h2 {
    margin: 15px 0 0;
    text-transform: capitalize;
  }
  .sh3-section-title .sh3-yellow-btn {
    font-weight: 700;
    display: none;
  }
  .sh3-review-section {
    padding: 80px 0;
    background: var(--primary-color);
  }
  .sh3-review-section h2 {
    color: #fff;
  }
  .sh3-review-section h2 span {
    font-size: 36px;
  }
    .sh3-review-star {
      display: inline-block;
      text-align: left;
    }
    .sh3-review-star i:not(:last-child) {
      margin-right: 5px;
    }
    .sh3-review-info p {
      color: #fff;
      margin: 5px 0 0;
    }
    .sh3-trust-content-btn .btn:not(:last-child) {
      margin-right: 15px;
    }
    .sh3-review-info {
        text-align: right;
      }
      .sh3-review-star i {
        background: #fff;
        color: #FFAA00;
        padding: 5px;
        border-radius: 5px;
      }
      .sh3-split-img img {
        width: 100%;
      }
    .sh3-split-img-framegrid {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      flex-flow: wrap;
      overflow: hidden;
    }
    .sh3-split-img {
      position: relative;
    }
    .sh3-split-img-frame1,.sh3-split-img-frame2,.sh3-split-img-frame3 {
      position: relative;
      top: 0;
      height: 100%;
      flex: 1 0 0;
    }
    .sh3-split-img-frame1:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-style: solid;
      border-width: 60px 5px;
      border-color: #fff;
    }
    .sh3-split-img-frame1:before {
      content: "";
      position: absolute;
      width: calc(100% - 10px);
      height: calc(100% - 120px);
      background: transparent;
      left: 5px;
      top: 60px;
      border-radius: 10px;
      box-shadow: 0px 0px 0 10px #fff;
    }
    .sh3-split-img-frame2:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-style: solid;
      border-width: 0px 5px;
      border-color: #fff;
    }
    .sh3-split-img-frame2:before {
      content: "";
      position: absolute;
      width: calc(100% - 20px);
      height: 100%;
      background: transparent;
      left: 10px;
      top: 0;
      border-radius: 10px;
      box-shadow: 0px 0px 0 10px #fff;
    }
    .sh3-split-img-frame3:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-style: solid;
      border-width: 60px 5px;
      border-color: #fff;
    }
    .sh3-split-img-frame3:before {
      content: "";
      position: absolute;
      width: calc(100% - 10px);
      height: calc(100% - 120px);
      background: transparent;
      left: 5px;
      top: 60px;
      border-radius: 10px;
      box-shadow: 0px 0px 0 10px #fff;
    }
      .sh3-trust-section {
        padding: 50px 0;
      }
      .sh3-trust-content p {
        color: #6D7A80;
        margin-bottom: 30px;
      }
      .sh3-trust-section .sh3-section-title {
        margin-bottom: 30px;
      }
      .sh3-trust-section h4 {
        margin: 0 0 5px;
      }
      .sh3-trust-section li {
        margin-bottom: 20px;
        padding-left: 30px;
        color: #6D7A80;
        background: url(../../../assets/img/icons/tick.png) no-repeat;
        background-position: left center;
      }
      .sh3-trust-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
      .sh3-howwe-section {
        padding: 80px 0;
        background: var(--primary-color);
      }
      .sh3-card-howwe {
        background: #fff;
        padding: 24px;
        border-radius: 10px;
        box-shadow: 0px 4px 44px rgba(197, 197, 197, 0.25);
        margin-bottom: 24px;
      }
      .sh3-card-howwe p {
        -webkit-line-clamp: 4;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        min-height: 85px;
    }
      .sh3-card-howwe-img img {
        border-radius: 10px;
        width: 100%;
        height: 170px;
      }
      .sh3-card-howwe-img {
        position: relative;
        margin-bottom: 24px;
      }
      .sh3-card-howwe-img span {
        position: absolute;
        display: flex;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background: var(--secondary-color);
        color: #fff;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 700;
        bottom: -20px;
        right: 20px;
      }
      .swiper-slide:nth-child(2n) .sh3-card-howwe-img span {
        background: var(--primary-color);
      }
      .sh3-card-howwe h3 {
        margin: 0 0 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sh3-cate-section {
        padding: 80px 0;
      }
     
      .sh3-card-cate img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
      .sh3-card-cate {
        box-shadow: 0px 2px 3px rgba(0,0,0,0.1);
        text-align: center;
        height: 100%;
      }
      .sh3-card-cateimg {
        display: block;
        height: 120px;
        margin: 0 0 10px;
    }    
    .sh3-card-cateimg img {
        max-height: 100%;
    }
      .sh3-card-cate-body {
        background: #fff;
        padding: 15px;
        text-align: left;
      }
      .sh3-card-cate-body h3 {
        margin: 10px 0;
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .sh3-card-cate-body .chips-container {
        margin: 0 0 20px;
    }
    .sh3-cate-section .swiper-slide {
      height: auto;
    }
      .sh3-card-cate-body .btn {
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        padding: 0;
      }
      .sh3-card-cate-body p {
        font-weight: 600;
      }
      .sh3-trust-section {
        padding: 50px 0;
      }
      .sh3-trust-content p {
        color: #6D7A80;
        margin-bottom: 30px;
      }
      .sh3-trust-section .sh3-section-title {
        margin-bottom: 30px;
      }
      .sh3-trust-section h4 {
        margin: 0 0 5px;
      }
      .sh3-trust-section li {
        margin-bottom: 20px;
        padding-left: 30px;
        color: #6D7A80;
        background: url(../../../assets/img/icons/tick.png) no-repeat;
        background-position: left center;
      }
      .sh3-trust-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
      .sh3-trust-content h2 {
        margin-bottom: 24px;
      }
      .sh3-trust-section .sh3-trust-content-media li {
        padding: 0;
        background: transparent;
      }
      .sh3-trust-content-media .sh3-icon-box {
        background: #fff;
        display: flex;
        width: 90px;
        height: 90px;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        border-radius: 10px;
        border: 1px solid var(--primary-color);
        padding: 10px;
      }
      .sh3-trust-content-media p {
        margin: 0;
      }  
      .sh3-serv-section {
        padding: 80px 0;
        background: var(--primary-color);
      }
      .sh3-card-serv-img img {
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 355px;
      }
      .sh3-card-serv-body {
        background: #fff;
        border-radius: 0 0 10px 10px;
        padding: 24px;
      }
      .sh3-card-serv-body p {
        margin: 15px 0;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }
      .sh3-card-serv-body h3 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .sh3-card-serv-body .btn {
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
      .sh3-card-serv-img {
        position: relative;
      }
      .sh3-card-serv-img::after {
        content: "";
        width: 100%;
        height: 100%;
        background: url(../../../assets/img/home/img-vector.png);
        position: absolute;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
      }  
      .sh3-appdata {
        display: flex;
        margin: 80px 0 0;
      }
      .sh3-appdata h4 {
        color: #fff;
    margin: 0 0 10px;
    font-size: 36px;
      }
      
    .sh3-appdata li {
        flex: 1 0 0;
      }
      .sh3-appdata .swiper-button-next::after,.sh3-appdata .swiper-button-prev::after {
        color: #fff;
      }
      .sh3-appdata li span,.sh3-appdata-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        width: 105px;
        height: 105px;
        border-radius: 10px;
        margin-right: 10px;
      }
      .sh3-appdata p {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
      .sh3-safety-section {
        padding: 80px 0;
      }
      .sh3-safety-content h3 {
        margin-bottom: 15px;
      }
      .sh3-safety-content li {
        margin-bottom: 24px;
      }
      .sh3-safety-content {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .sh3-safety-list-card {
        position: relative;
        border-radius: 10px;
        padding: 20px;
        color: #fff;
        margin-bottom: 15px;
        background: var(--primary-color) !important;
      }
      .sh3-safety-list-card-bg1 {
        background: var(--primary-color);
      }
      .sh3-safety-list-card-bg2 {
        background: var(--secondary-color);
      }
      .sh3-safety-list-card-bg3 {
        background: #1BB333;
      }
      .mob-user li a {
        padding: 5px;
    }
    .sh3-howwe-section .swiper-slide {
      height: auto;
    }
    .sh3-howwe-section .sh3-card-howwe {
      height: 100%;
    }
      .sh3-safety-list-card h3 {
        margin-bottom: 15px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sh3-safety-list-card p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        display: -webkit-box;
        min-height: 72px;
        color: #fff;
      }
      .sh3-faq-section {
        position: relative;
        padding: 0;
      } 
      .sh3-faq-home .accordion-item {
        margin-bottom: 15px;
        border: 0;
      } 
      .sh3-faq-home .accordion-button {
        font-weight: 700;
        color: #191919;
        font-size: 18px;
        box-shadow: 0 0 0;
        background: #F5F5F5;
      }
      .sh3-faq-section .sh3-split-img-frame3::before,.sh3-faq-section .sh3-split-img-frame1::before,.sh3-faq-section .sh3-split-img-frame2::before {
        box-shadow: 0px 0px 0 10px #Fff;
      }
    
      .sh3-faq-section .sh3-split-img-frame3::after,.sh3-faq-section .sh3-split-img-frame1::after,.sh3-faq-section .sh3-split-img-frame2::after {
        border-color: #fff;
      }
      
      .sh3-faq-home .accordion-button:not(.collapsed) {
        background: #fff;
      }
      .sh3-faq-home .accordion-button::after {
        background-image: url(../../../assets/img/icons/plus.png);
      }
      .sh3-faq-home .accordion-button:not(.collapsed)::after { 
        background-image: url(../../../assets/img/icons/minus.png);
      }
      .sh3-testimonial-card {
        background: #fff;
        padding: 24px;
        border-radius: 10px;
        border: 1px solid rgba(46, 54, 57, 0.25);
      }
      .sh3-profile-user {
        display: flex;
        margin-bottom: 20px;
        flex-flow: wrap;
      }
      .sh3-profile-user-img img {
        border: 2px solid #F0F0F0;
        width: 60px;
        height: 60px;
      }
      .sh3-profile-user-img {
        margin-right: 8px;
        width: 60px;
        height: 60px;
      }
      .sh3-profile-user-info {
        width: calc(100% - 70px);
      }
      .sh3-profile-user-info h6 {
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sh3-profile-user-info h6 a {
        color: #192335;
      }
      .sh3-profile-rating span {
        margin-right: 5px;
        font-size: 14px;
      }
      .sh3-testimonial-card p {
        color: #6D7A80;
        -webkit-line-clamp: 4;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        min-height: 95px;
      }
      .sh3-testimonial-img img {
        border-radius: 5px;
        width: 100%;
      }
      .sh3-testimonial-img {
        position: relative;
      }
      .sh3-testimonial-overlay {
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 30px;
        display: flex;
        align-items: end;
        height: 100%;
        z-index: 1;
      }
      .sh3-play-btn {
        width: 60px;
        height: 60px;
        background: #FF0059;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        margin-left: auto;
        font-size: 32px;
      }
      .sh3-testimonial-overlay p {
        width: calc(100% - 80px);
      }
      .sh3-testimonial-overlay:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.65);
        left: 0;
        top: 0;
        border-radius: 10px;
        z-index: -1;
      }
      .sh3-testimonial-section {
        position: relative;
        padding: 80px 0;
      }
      .sh3-testimonial-card h5 {
        margin: 0 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sh3-mobapp-section {
        position: relative;
        padding: 80px 0;
      }
      
    .sh3-app-highlights .sh3-icon-box {
        display: flex;
        width: 60px;
        height: 60px;
        background: #fff;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 44px rgba(197, 197, 197, 0.25);
        margin-right: 10px;
        border: 1px solid #F0F0F0;
        border-radius: 10px;
      }
      .sh3-app-highlights li {
        margin-bottom: 15px;
      }
      .sh3-mobapp-subtract-body {
        background: var(--secondary-color);
      }
      
    .sh3-mobapp-content p {
        color: #354750;
      }
      .sh3-applinks {
        margin: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
      }
      .sh3-qrbox {
        border: 1px solid #fff;
        padding: 10px;
        border-radius: 8px;
        display: inline-block;
      }
      .sh3-applinks a {
        display: inline-block;
      }
      .formonlybtn .sh3-postcodeform .dropdown {
        display: none;
      }
      .sh3-postcodeform .dropdown{
        position: absolute;
        margin-top: 54px;
        background: #fff;
        width: 29rem;
        box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
        border-radius: 5px;
        z-index: 99;
    } 
    .sh3-latestnews-card {
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
  }
  .sh3-latestnews-img {
    position: relative;
}
.sh3-latestnews-img img {
  width: 100%;
}
.sh3-latestnews-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  padding: 24px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.sh3-latestnews-img:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(359.28deg, #000000 -17.37%, rgba(0, 0, 0, 0) 71.26%);
  top: 0;
  left: 0;
}
.sh3-latestnews-addfav, .sh3-latestnews-top {
  align-items: center;
  display: flex;
}
.sh3-latestnews-tag {
  background: #1b9db3;
  background: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  padding: 2px 8px;
}
.sh3-latestnews-bottom {
  margin-top: auto;
}
.sh3-latestnews-bottom .sh3-profile-user {
  align-items: center;
}
.sh3-profile-user-info.sh3-latestnews-user-info {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.sh3-latestnews-date {
  border-left: 1px solid #fff;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  padding-left: 10px;
}
.sh3-latestnews-bottom h3 {
  color: #fff;
  font-weight: 600;
}
.sh3-latestnews-user-info {
  color: #fff;
}
.sh3-latestnews-section {
  padding: 80px 0;
}
      .sh3-footer {
        background-position: 0 -75px;
        color: #c2c2c2;
        padding-top: 80px;
    }
    .sh3-footer-sectionthree {
        padding: 0 0 25px;
    }
    .sh3-footer-sectionfour {
        padding: 20px 0; 
    }
    .sh3-logo-footer {
        margin-bottom: 20px;
    }
    .sh3-footer p {
        color: var(--footer-paragraph);
    }

    .sh3-footer-social {
        margin-top: 20px;
    }
    .sh3-footer-social li {
        display: inline-block;
        margin-right: 20px;
    }
    
    .sh3-footer-sectionthree li {
        margin-bottom: 15px;
    }
    .sh3-footer-social a {
        align-items: center;
        border: 1px solid #fff;
        border-radius: 50%;
        color: #fff;
        display: flex;
        font-size: 14px;
        height: 25px;
        justify-content: center;
        width: 25px;
    }
    .sh3-footer h4 {
        color: #fff;
        color: var(--footer-header);
        margin-bottom: 20px;
    }
    .sh3-footer-sectionthree a {
        color: #c2c2c2;
    }
    .sh3-footer-sectionthree a.primary-text {
        color: #1b9db3;
        color: var(--primary-color);
    }
    .sh3-footer-last a {
        color: inherit;
        text-decoration: none;
    }

    @media only screen and (max-width: 767px) { 
      .sh3-logo #site_logo {
        max-height: 70px;
      }
      .h2, h2 {
        font-size: 20px;
    }
    .sh3-trust-content h2 {
      margin-bottom: 15px;
    }
    .h4, h4,.sh3-latestnews-bottom h3 {
      font-size: 18px;
    }
    .sh3-appdata h4 {
      font-size: 20px;
    }
      .sh3-cate-section .swiper-wrapper {
        justify-content: unset;
    }
      .sh3-split-img {
        margin: 0 0 10px;
      }
      .sh3-priority-card {
        padding: 20px 40px;
    }    
    .sh3-review-star {
      display: block;
      text-align: center;
      margin: 10px 0 0;
  }
    .sh3-priority-card h3 {
        margin: 10px 0;
    }
      .sh3-home_single_search h1 {
        font-size: 30px;
    }
    
    .sh3-priority-section {
        padding: 20px 0;
    }
    
    .sh3-review-section {
        padding: 20px 0;
        margin: 0 0 10px;
    }
    
    .sh3-trust-section {
        padding: 0 0 20px;
    }
    
    .sh3-howwe-section {
        padding: 20px 0;
    }
    
    .sh3-cate-section {
        padding: 20px 0;
    }
    
    .sh3-section-title {
        margin: 0 0 20px;
    }
    
    .sh3-serv-section {
        padding: 20px 0;
    }
    
    .sh3-appdata {
        margin: 30px 0 0;
    }
    
    .sh3-safety-section {
        padding: 20px 0;
    }
    
    .sh3-safety-content {
        margin: 20px 0 0;
    }
    
    .sh3-safety-list-card {
        margin-top: 10px;
    }
    
    .sh3-faq-home.accordion {
        margin: 20px 0 0;
    }
    
    .sh3-testimonial-section {
        padding: 20px 0;
    }
    
    .sh3-mobapp-section {
        padding: 0;
    }
    
    .sh3-applinks {
        margin: 20px  0;
    }
    
    .sh3-latestnews-section {
        padding: 20px 0;
    }
    
    .sh3-footer {
        padding-top: 0;
    }
    
    .sh3-footer-sectionthree {
        padding: 0;
    }
      .sh3-card-cate img { 
        width: auto;
        border-radius: 0;
      }
      .sh3-testimonial-card,.sh3-card-howwe {
        padding: 20px 45px;
      }
      .sh3-appdata .media.d-flex {
        padding: 0 40px;
    }
    }