@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&family=Poppins:wght@700&display=swap');
.loading {
    z-index: 999999;
    margin: 0 auto;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
  }
  .ecomadrscheck {
    display: flex;
    position: relative;
    flex-direction: column;
}
.ecomadrselect .adrsselect-or,.ecomadrselect .adrsselect-link a {
    color: #333;

} 
.ecomadrscheckgrid {
    position: relative;
}
.nonotify {
    text-align: center;
    padding: 40px 0;
}
.header-link .notify-seeall a {
    color: #333;
    font-size: 14px;
    padding: 15px 0;
}
.notification-header p a {
    color: #333;
}
.btn-primarynote:hover {
    color: #fff;
}
.btn-primarynote {
    background: #38393d;
    margin: 10px 0;
    color: #fff;
}
.social-login .btnwtext {
    display: flex;
    background: #000;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 40px;
}
.social-login .btnwtext:hover {
    color: #fff;
}
.social-login .btn {
    width: 50%;
    font-family: Inter;
    font-weight: 400;
}

.btnwtext img {
    margin: 0 5px 0 0;
    max-width: 24px;
}
  @keyframes ssc-loading {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}
.ssc {
    cursor: progress;
    user-select: none;
}
.ssc .mb {
        margin-bottom: 16px;
    }

    .ssc .mt {
        margin-top: 16px;
    }

    .ssc .mr {
        margin-right: 16px;
    }

    .ssc .ml {
        margin-left: 16px;
    }

    .ssc .mbs {
        margin-bottom: 8px;
    }

    .ssc .mts {
        margin-top: 8px;
    }

    .ssc .mrs {
        margin-right: 8px;
    }

    .ssc .mls {
        margin-left: 8px;
    }

    .ssc .w-10 {
        width: 10%;
    }

    .ssc .w-20 {
        width: 20%;
    }

    .ssc .w-30 {
        width: 30%;
    }

    .ssc .w-40 {
        width: 40%;
    }

    .ssc .w-50 {
        width: 50%;
    }

    .ssc .w-60 {
        width: 60%;
    }

    .ssc .w-70 {
        width: 70%;
    }

    .ssc .w-80 {
        width: 80%;
    }

    .ssc .w-90 {
        width: 90%;
    }

    .ssc .w-100 {
        width: 100%;
    }

    .ssc .flex {
        display: flex;
    }

    .ssc .inline-flex {
        display: inline-flex;
    }

    .ssc .flex-column {
        flex-direction: column;
    }

    .ssc .flex-column-reverse {
        flex-direction: column-reverse;
    }

    .ssc .flex-row {
        flex-direction: row;
    }

    .ssc .flex-row-reverse {
        flex-direction: row-reverse;
    }

    .ssc .align-center {
        align-items: center;
    }

    .ssc .align-start {
        align-items: flex-start;
    }

    .ssc .align-end {
        align-items: flex-end;
    }

    .ssc .align-stretch {
        align-items: stretch;
    }

    .ssc .justify-start {
        justify-content: start;
    }

    .ssc .justify-center {
        justify-content: center;
    }

    .ssc .justify-end {
        justify-content: end;
    }

    .ssc .justify-between {
        justify-content: space-between;
    }

    .ssc .justify-around {
        justify-content: space-around;
    }
    .ssc-wrapper {
        padding: 16px;
    }
    
    /* Figures, Objects */
    .ssc-card {
        display: none;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #fff;
    }
    .ssc-base {
        display: block;
        background-color: rgba(0, 0, 0, 0.17);
        position: relative;
        overflow: hidden;
    }
    .ssc-circle {
        border-radius: 50%;
        width: 50px;
        height: 50px;        
    }
    .ssc-base::after { 
        content: "";
        animation: ssc-loading 1.3s infinite;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-100%);
        z-index: 1;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.3), transparent);
    }
    .ssc-hr {
        width: 100%;
        height: 2px;       
    }
    
    .ssc-line {
        border-radius: 15px;
        width: 100%;
        height: 12px;
    }
    
    .ssc-head-line {
        border-radius: 15px;
        width: 100%;
        height: 24px;
    }
    
    .ssc-square {
        width: 100%;
        height: 150px;
    }
    .ssc-card-container {
        position: fixed;
        z-index: 999;
    }
    .ssc-card-container {
        position: fixed;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: #fff;
      }


      .ttslider img {
        width: 100%;
        cursor: pointer;
    }
    .tt-grid {
        margin: 20px 0;
    }
    
    .tt-sidebar {
        margin: 20px 0;
        background: #000;
        padding: 10px;
    }
    
    .tt-img {
        text-align: center;
        margin: 0 0 20px;
    }
    
    .tt-grid p {
        text-align: justify;
    }
    
    .tt-grid p:not(:last-child) {
        margin-bottom: 20px;
    }
    
    .tt-sidebar a {
        color: #fff;
        padding: 10px 20px;
        display: block;
        transition: all 0.5s;
    }
    
    .tt-sidebar a.active {
        background: #fff;
        color: #000;
        border-radius: 2px;
    }
    .tt-sidebar a:hover {
        background: #dedee0;
        color: #000; 
    }
    .tt-grid h4 {
        font-weight: bold;
        color: red;
        margin: 20px 0;
    }
    
    .tt-grid li {
        list-style: disc;
    }
    
    .tt-grid ul {
        padding: 0 0 0 20px;
    }
    
    .tt-grid li:not(:last-child) {
        margin-bottom: 5px;
    }
.ttslider .swiper-button-next::after,
.ttslider .swiper-button-prev::after {
    color: #fff;
    border: 0;
    font-size: 46px !important;
}
.ttslider a {
    display: block;
}
.urlplaceholder {
    flex: 1 0 auto;
    position: relative;
}

.urlplaceholder:after {
    content: "https://";
    position: absolute;
    top: 7px;
    left: 7px;
}

.urlplaceholder .form-control {
    padding-left: 70px;
}
.acc-payments {
    margin: 15px 0 0;
}
  .edit-section .swiper-button-next::after,.edit-section .swiper-button-prev::after 
  { 
    background: #192335;
    border-radius: 0;
    height: 100%;
    position: absolute;
    color: #fff;
  }
  .nav-item.nav-option {
    position: relative;
  }
  .tab-close {
    position: absolute;
    right: 10px;
    top: 9px;
    background: #ffff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
  }
  .img-bg {
    border: 2px solid #fff;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin: 20px 0;
}
.btncolorpicker > span {
  height: 100%;
}
.img-bg img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.img-option img {
  height: 40px;
  border: 4px solid #fff;
  border-radius: 2px;
  box-shadow: 1px 4px 4px rgba(0,0,0,0.5);
}

.form-contact,.contgrid {
    border: 1px solid #c9c9c9;
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 25px;
  }
  .contgrid i {
    color: var(--primary-color);
  }
  .contgrid a {
    color: #6B7385;
  }



  .trust-section2 li {
    margin-bottom: 20px;
    padding-left: 30px;
    color: #6d7a80;
    background: url(../img/icons/tick.svg) no-repeat;
    background-position: left center;
  }
  .personal-info .fa-copy{
    margin-left: 10px;
    cursor: pointer;
  }
  .product-dn .pro-det{
    min-width: 30%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .offer-section-4 {
    padding: 0 0 70px;
}
  .offer-section-4grid {
    background: var(--primary-color) url(../img/offer-banner-bg.png) no-repeat;
    background-position: top;
    padding: 53px 20px;
  }
  .offer-section-4grid .swiper-button-next::after,.offer-section-4grid .swiper-button-prev::after { 
    color: #fff;
  }
  .offer-card{
    background: rgba(255,255,255,0.3);
    padding: 10px;
    border-radius: 5px;
  }
  .offer-wrap-price {
    display: flex;
    font-size: 26px;
    font-weight: 600;
    align-items: center;
  }
  .offer-wrap-price i{
    font-size: 20px;
    cursor: pointer;
  }
  .offer-wrap-left {
    color: #fff;
    font-size: 40px;
  }
  .offer-wrap-right img {
    margin-top: 0;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 3px;
    width: 100%;
    height: 100%;
  }
  .offer-wrap-right {
    display: none;
    width: 50px;
    height: 50px;
    justify-content: center;
  }
  .offermodal-body {
    border: none;
    border-radius: 0;
    border-radius: 30px;
    color: #333;
    padding: 30px;
    text-align: center;
}
.offermodal-body h3 {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0 15px;
}
.offermodal-body h4 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.offermodal-body h4.promo {
    margin-top: 30px;
}
.offermodal-body h4 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.offermodal-body p {
    font-size: 16px;
    margin: 0;
}
.offercode {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 5px;
    display: flex;
    margin: 10px 0;
    position: relative;
    height: 70px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.4);
    border-radius: 4px;
    background: var(--primary-color);
    color: #fff !important;
}
.offer-modal-body .close-btn {
    color: #333;
  }
.offercode i {
    cursor: pointer;
}

.es-main-container a{
    display: none !important;
}
.pradcart {
    position: relative;
  }
  .pradcart input[type="checkbox"] {
    display: none;
  }
  .pradcart input[type="checkbox"] + label {
    position: relative;
    width: 100px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
  }
  .pradcart input[type="checkbox"] + label:after {
    position: absolute;
    content: "ADD";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--primary-color);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
  }
  
  .pradcart input[type="checkbox"] + label:before {
    content: "Remove";
    position: absolute;
    width: 100%;
    height: 100%;
    background: red;
    right: -100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 1;
    transition: all 0.5s;
    opacity: 0.4;
  }
  
  .pradcart input[type="checkbox"]:checked + label:before {
    right: 0;
    transition: all 0.5s;
  }
  
  .pradcart input[type="checkbox"]:checked + label:after {
    left: -100%;
  }
.product.product-dn {
    background-color: #fff;
    text-align: center;
    width:100%;
    margin-bottom: 30px;
    box-shadow: 0px 4px 24px rgba(228, 228, 228, 0.25);
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    position: relative;
    padding: 15px;
    display: flex;
    height: 100%;
    flex-direction: column;
}
.policy-section * {
    max-width: 100%;
}
.product.product-dn .pro-det a {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-word;
    flex-direction: column;
    background: #fff;
    font-size: 14px;
    word-wrap: break-word;
    text-decoration: none;
}
.product.product-dn .pro-det a img {
    width: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}
.product.product-dn .pro-det a h5 {
    font-size: 20px;
    padding-top: 0;
    margin-bottom: 15px;
    max-width: 100%;
    color: #4A4A4A;
    font-weight: 600;
}
#list-cart {
    height: 100%;
    display: flex;
  }
.product.product-dn .pro-det a p.product-option {
    color: #777;
    font-size: 13px;
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.product.product-dn .btn-group {
    margin-bottom: 20px;
}
.product-dn .btn-group, .btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.product-dn .btn-group>.btn:first-child {
    margin-left: 0;
}
.product.product-dn .option-btn {
    border: 0;
    padding: 0;
    background-color: #fff;
    box-shadow: unset;
    font-size: 11px;
}
.product.product-dn .option-btn {
    border: 0;
    padding: 0;
    background-color: #fff;
    box-shadow: unset;
    font-size: 11px;
}
.product-dn .btn .caret {
    margin-left: 0;
}
.product-dn .price-amount {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    margin-left: 0;
    width: 100%;
}
.product-dn .amount {
    font-size: 22px;
    font-weight: 600;
}
.home-shop-h2 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 26px;
}
		.shop-hdrspan {
    font-size: 28px;
}
.home-shop-hdr {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    justify-content: space-between;
  }
  .section-shop-info {
    margin: 40px 0 0;
}
.section-shop-hr {
    padding-bottom: 30px;
    border-bottom: 1px solid #cdcdcd;
}
.sec-shop-details h2 {
    margin: 0 0 10px;
    font-weight: 600;
}
.sec-shop-adrs {
    font-size: 16px;
}
.sec-shop-adrs i {
    color: var(--primary-color);
}
.sec-shop-details li:not(:last-child) {
    margin: 0 0 7px;
}
.sec-shop-details li {
    font-size: 16px;
}
.sec-shop-img img {
    border-radius: 5px;
}
.sec-shop-details li i {
    color: var(--primary-color);
}
.rbpsec {
    padding: 40px 0;
}
.rbptable h3 {
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0 0 20px;
}
.rbptable table {
    border: 1px solid #dee2e6;
}
.rbptable td {
    padding: 15px !important;
    font-size: 18px;
    font-weight: 600;
}
.rbptable .rbpstext {
    font-size: 14px;
    vertical-align: bottom;
    color: #a9a9ae;
    text-align: right;
}
.rbpimg img {
    border-radius: 4px;
    margin: 0 0 15px;
}
.rbptable th {
    font-size: 18px;
    padding: 15px;
}
.w-cus1 {
    white-space: nowrap;
}
.rbprctext {
    display: block;
    font-size: 16px;
    opacity: 0.8;
}
.maintenance-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    text-align: center;
    padding: 20px;
}
.maintenance-img img {
    max-height: 50vh;
  }
.maintenance {
    min-height: 100vh;
}
.maintenance-card h2 {
    margin: 30px 0;
    font-weight: 700;
}
.maintenance-card h4 {
    margin: 0 0 25px;
    font-weight: 700;
}
.home-shop-hdr {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    justify-content: space-between;
  }
  .shop-grid {
    box-shadow: 0px 4px 10px 0px #0000000D;
    border-radius: 10px;
    margin-bottom: 25px;
    border: 1px solid #E6E6E6;
    overflow: hidden;
}

.shop-img img{
    height: 110px;
}
.shop-details-adrs {
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    min-height: 40px;
    margin: 0 0 6px;
}
.shop-details h3 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shop-details {
    padding: 20px;
}
.shop-details h3 {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.shop-details ul {
    padding: 0;
}

.shop-details li {
    list-style: none;
    margin: 0 0 10px;
    display: flex;
    justify-content: space-between;
}

.shop-price {
    font-weight: 600;
}
.shop-grid {
    border-radius: 0;
}

.shop-modal .modal-content {
    background: #2b2f3e;
}
.shop-modal .modal-header {
    border: 0;
}
.shop-modal .btn-close {
    filter: brightness(0) invert(1);
}
.shop-modal .modal-title.h4 {
    text-align: center;
    color: #fff;
    width: 100%;
}
.dropdown-heading {
    font-weight: 600;
    padding: 10px 10px;
    font-size: 18px;
    background: #f2f2f2;
  }
  .postcodeform .dropdown {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
  [class$="postcodeform"] .dropdown {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }

  .home_single_search .postcodeform .d-inpgroup input[type=text] {
    border-radius: 0;
    box-shadow: 0 0 0;
    background: #fff;
    border: 0;
  }
  .d-inpgroup {
    display: flex;
    width: 100%;
    border: 1px #000;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0,0,0,.4);
    background: #fff;
    overflow: hidden;
}
.d-inpgroup .form-select{ 
    width: auto;
    border-radius: 0;
}

.cookies-banner {
    position: fixed;
    bottom: 20px;
    z-index: 999999;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    align-items: center;
    max-width: calc(100% - 20px);
}

.cookies-banner-grid {
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 8px 28px rgba(0,0,0,.28);
    max-width: 100%;
}

.cookies-banner-grid-links {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
}

.cookies-banner-grid-links button {
    height: 45px;
    min-width: 150px;
    padding: 0 20px;
    border-radius: 35px;
    border: 2px solid;
    font-weight: 600;
}

.cookies-banner-grid-links a {
    text-decoration: underline !important;
    color: #2fa0ea;
    font-weight: 600;
}

.cookies-btn1 {
    background: #2fa0ea;
    color: #fff;
}

.cookies-btn2 {
    background: #fff;
    color: #2fa0ea;
}

.cookiepop-hdr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
}

.cookiepop-toggle {
    background: #fff;
    padding: 10px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.07);
    margin: 0 0 20px;
    border-radius: 2px;
}

.cookiepop-toggle-hdr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cookiepop-toggle-hdr button {
    background: transparent;
    border: 0;
}

.cookiepop-toggle-hdr button i {
    margin-right: 10px;
}

.cookiepop-toggle p {
    padding: 20px 0 0;
    border-top: 1px solid #eaeeef;
    margin-top: 20px;
}
.cookiepop-toggle .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .cookiepop-toggle .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .cookiepop-toggle .sliders {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .cookiepop-toggle .sliders:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .cookiepop-toggle input:checked + .sliders {
    background-color: #2fa0ea;
  }
  
  .cookiepop-toggle input:focus + .sliders {
    box-shadow: 0 0 1px #2fa0ea;
  }
  
  .cookiepop-toggle input:checked + .sliders:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .cookiepop-toggle .sliders.round {
    border-radius: 34px;
  }
  
  .cookiepop-toggle .sliders.round:before {
    border-radius: 50%;
  }
#app_cookie_popup {
    background: rgba(0, 0, 0, 0.7);
}
.shop-serv-link .btn {
    font-weight: normal;
    color: #fff;
}
.shop-serv-link .btn:hover {
    color: #fff;
    opacity: 0.9;
}
.shop-serv-link .btn i {
    margin-left: 10px;
}

.shop-serv-link .btn1 {
    background: var(--primary-color);
}

.shop-serv-link .btn2 {
    background: var(--secondary-color);
}

.shop-serv-link {
    margin: 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chips-container .chip {
    display: block;
    position: relative;
    padding: 0 0 0 15px;
}

.chips-container .chip:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: #212529;
    left: 0;
    top: 8px;
    border-radius: 50%;
}
.viewMore {
    margin: 10px 0 0;
    display: block;
    text-decoration: underline;
}
.card.card-bt-form {
    padding: 40px;
    margin: 20px 0;
}

.card-bt-form .p-button {
    background: var(--primary-color);
    border-color: var(--primary-color);
}
.card-bt-form .p-inputtext,.card-bt-form .p-dropdown {
    border-color: var(--bs-border-color);
}
/* .react-time-picker__wrapper button {
    display: none;
}

.react-time-picker__inputGroup {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.react-time-picker__inputGroup * {
    flex: 1;
}

span.react-time-picker__inputGroup__divider {
    text-align: center;
    font-weight: 800;
} */
.react-time-picker__clock {
    display: none;
}

button.react-time-picker__clock-button.react-time-picker__button {
    display: none;
}

.react-time-picker__wrapper {
    border: 0 !important;
}
@media only screen and (max-width:575px){ 
    .policy-section h1 {
        font-size: 28px !important;
    }
    .offermodal-body h3{ font-size:30px; padding:0 0 15px;}
    .offermodal-body h4{ font-size:18px;}
    .offermodal-body p{ font-size:14px; line-height:20px;}
    .offermodal-body .offercode{ font-size:30px; height:55px;}
    .offermodal-body .offercode span{ padding:2px 30px;}
    .offermodal-body .offercode:before, .offermodal-body .offercode:after{ height:55px;}
    .cookies-banner-grid-links button {
        min-width: auto;
    }
    .cookies-banner-grid-links {
        gap: 5px;
    }
    .cookies-banner-grid {
        padding: 15px;
    }
}