@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&family=Varela&display=swap');
.sh4-body {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    color: #6B7385;
    font-weight: 400;
    font-style: normal;
    background: #fff;
}
a {
    text-decoration: none;
}
li {
    list-style-type: none;
}
ol, ul {
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4,h5,h6,.title {
    color: #192335;
    margin: 0;
  }
  img {
    max-width: 100%;
}
.btn {
    font-weight: 700;
  }
  .primary-text {
    color: var(--primary-color);
  }
  .secondary-text {
    color: var(--secondary-color);
  }
  .primary-btn {
    background-color: var(--primary-color);
    padding: 15px 20px;
    border-radius: 10px;
    color: #fff;
  }
  .secondary-btn {
    background: var(--secondary-color);
    padding: 15px 20px;
    border-radius: 10px;
    color: #fff;
  }
  .title-btn {
    background: #192335;
    padding: 15px 20px;
    border-radius: 10px;
    color: #fff;
  }
  .border-btn {
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    font-weight: 500;  
    padding: 15px 20px;
  }
  .title-btn:hover, .primary-btn:hover,.secondary-btn:hover {
      color: #fff;
  }
  .yellow-btn {
    background: #FDD159;
    padding: 10px 20px;
    border-radius: 25px;
    color: #192335;
    display: inline-block;  
    line-height: normal;
  }
  
.ssc-card-container {
    display: none!important;
}
.section-editor.section-editor4::after {
  top: 165px;
  height: calc(100% - 165px);
}
.section-editor.section-editor4 .section-editor-icon {
  top: 165px;
}
.sh4-header {
    position: relative;
      z-index: 9;
      width: 100%;
      top: 0;
      -webkit-transition: all 0.5s ease;
      -moz-transition: position 10s;
      -ms-transition: position 10s;
      -o-transition: position 10s;
      transition: all 0.5s ease;
  }
  .sh4-header-nav {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
  }
  .sh4-header-topnav-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
  }
  .sh4-header-topnav-contact li {
    display: inline-block;
  }
  .sh4-header-topnav-contact li:not(:last-child) {
    margin-right: 25px;
  }
  .sh4-header-topnav-contact a {
    color: var(--secondary-color);
  }
  .sh4-logo {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-between;
  }
  #site_logo {
      max-height: 95px;
      max-width: 100%;
    }
  ul.sh4-header-link {
      display: flex;
      height: 100%;
      align-items: center;
  }
  ul.sh4-header-link li {
      display: inline-block;
      padding: 0 10px;
  }
  .sh4-header-link li a {
      font-size: 20px;
      font-weight: 700;
      color: var(--nav-color);
      text-decoration: none;
      text-decoration: none;
      transition: .5s all;
      -webkit-transition: .5s all;
      -moz-transition: .5s all;
      -o-transition: .5s all;
      -ms-transition: .5s all;
      display: block;
  }
  .sh4-header.fixed .sh4-header-top {
    display: none;
}
  .sh4-header-link li .nav-bar-link.active {
      color: var(--nav-color-active);
      position: relative;
    }
  .sh4-header-link li a:hover {
      color: var(--nav-color-hover);
      text-decoration: none;
  }
  .sh4-header-link li .cart-btn {
      align-items: center;
      display: inline-flex;
      padding: 0;
      background-color: #fff;
      transition: 250ms;
      color: #000;
      border-radius: 50px;
      width: 48px;
      justify-content: center;
      height: 48px;
      position: relative;
  }
  .sh4-cart-list {
      position: relative;
  }
  .sh4-login-link a > .sh4-color-theme {
      color: #fff !important;
      font-weight: 800;
      text-decoration: none;
  }
  .sh4-banner-btn-group {
    margin: 30px 0 60px;
  }
  .sh4-banner-btn-group .btn:not(:last-child),.sh4-banner-app li:not(:last-child) {
    margin-right: 15px;
  }
  .sh4-banner-app-links li {
    display: inline-block;
  }
  .sh4-banner-app h4 {
    margin-bottom: 20px;
  }
  .sh4-heroimg {
    margin: 24px 0;
  }
  .sh4-banner-search-wrap {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-bottom: 150px;
    justify-content: end;
  }
  .sh4-home_single_search {
      background-size: cover !important;
      margin-top: -200px;
      overflow: hidden;
      padding: 225px 0 0;
      position: relative;
      width: 100%;
      z-index: 1;
  }
  .sh4-home_single_search h1 {
      font-weight: 400;
      margin: 24px 0;
      font-size: 50px;
      font-family: Varela;
  }
  .sh4-postcodeform {
      width: 100%;
      margin-bottom: 0px;
      display: flex;
      overflow: hidden;
      box-shadow: 0px 32px 41px rgba(210, 210, 210, 0.25);
  }
  .sh4-postcodeform input[type=text] {
      padding: 0 15px;
      border: 1px solid #BFBFBF;
      width: 100%;
      outline: 0;
      font-size: 16px;
      height: 54px;
      border-radius: 5px;
      margin-right: 25px;
  }
  .sh4-postcodeform .btn a{
      text-decoration: none;
      color: #fff;
  }
  .sh4-home-offer {
    background: #FDD159;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }
  .sh4-home-offer .btn {
    background: #fff;
    margin: 0 0 0 10px;
    color: var(--primary-color);
    padding: 5px 10px;
    line-height: normal;
  }
  .sh4-header-link-right {
      margin-left: auto;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .sh4-header-link-right li {
      display: inline-block;
      font-weight: 500;
    }
  .sh4-header-link-right li:not(:first-child) {
      margin-left: 12px;
    }
    .sh4-header-link-right img {
      margin-right: 5px;
    }
    .sh4-header-auth a {
      background: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 10px 25px;
      font-weight: 500;
      color: #fff;
      height: 50px;
    }
    .sh4-header-auth a i {
      margin-right: 8px;
    }
    .sh4-header-link-right .title-btn i {
      margin-right: 8px;
    }
  .sh4-count-blk {
      display: block;
      font-weight: 600;
      font-size: 16px;
    }
    .sh4-search-wrapper .sh4-searchh4 {
      padding-left: 20px;
    }
  .sh4-partner-section {
    padding: 45px 0;
    background: #D9F3E6;
    text-align: center;
  }
  .sh4-header-cart-box {
    display: flex;
    align-items: center;
    padding: 7px 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
  }
  .sh4-header-cart-box i {
    background: var(--secondary-color);
    color: #fff;
    padding: 0 10px;
    margin-right: 10px;
    border-radius: 5px 0 0 5px;
    font-size: 26px;
  }
  .sh4-cart-box-info {
    padding: 0 15px 0 0;
    font-size: 14px;
    line-height: normal;
  }
    .sh4-banner-tag {
      display: inline-block;
      font-size: 23px;
      color: #192335;
    }
    .sh4-search-wrapper .sh4-postcodeform-container {
        background: hsla(0,0%,100%,.2);
        padding: 20px;
        position: relative;
      }
      .sh4-postcodeform input[type=submit],.sh4-postcodeform .btn {
        padding: 15px;
        font-size: 16px;
        border: 0;
        background-color: var(--primary-color);
        flex: 0 0 auto;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        border-radius: 10px;
        color: #fff;
        font-weight: 700;
    }
    .sh4-home_single_search h1 span {
        color: var(--primary-color);
        text-decoration: underline;
    }
    .sh4-priority-section {
        padding: 70px 0;
      }
      .sh4-priority-card {
        background: #fff;
        padding: 14px;
        border-radius: 5px;
        margin-bottom: 24px;
        transition: all 0.5s;
        border: 2px solid rgba(136, 136, 136, 0.2);
      }
      .sh4-priority-card:hover {
        background: var(--primary-color);
      }
      .sh4-priority-card:hover *{
        color: #fff;
      }
      .sh4-priority-card h3 {
        margin: 10px 0;
        font-weight: 400;
      }
      
    .sh4-priority-card-img {
      position: relative;
      z-index: 1;
      border-radius: 5px;
      overflow: hidden;
    }
    .sh4-priority-card-img-overlay {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      z-index: 2;
      color: #fff;
      display: flex;
      top: 0;
      align-items: end;
      padding: 20px 30px;
      font-size: 32px;
    }
    .sh4-priority-card-img:after {
      content: "";
      position: absolute;
      width: 85px;
      height: 85px;
      border-top: 10px solid #fff;
      border-right: 10px solid #fff;
      z-index: 3;
      right: 20px;
      top: 20px;
    }
      .sh4-load-more p {
        display: inline-block;
      }
      .sh4-load-more {
        margin-top: 16px;
        text-align: center;
      }  
      .sh4-home-offer-close {
        position: absolute;
        right: 15px;
        color: #fff;
        font-size: 20px;
      }
    .sh4-priority-img img {
        border-radius: 8px;
        border-radius: 8px;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    transition: ease all 0.5s;
      }
      .sh4-priority-img {
        overflow: hidden;
        -webkit-transition: ease all 0.5s;
        -ms-transition: ease all 0.5s;
        transition: ease all 0.5s;
      }
      .sh4-priority-img img:hover {
        -webkit-transition: ease all 0.5s;
        -ms-transition: ease all 0.5s;
        transition: ease all 0.5s;
        transform: scale(1.1) rotate(3deg);
        -webkit-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
      }
      .sh4-section-title {
        text-align: center;
        margin: 0 0 50px;
        font-family: Varela;
      }
      .sh4-section-title h4 {
        margin-bottom: 15px;
        color: #888888;
      }
    .sh4-section-title h2 {
      text-transform: capitalize;
      display: inline-block;
      position: relative;
      font-size: 41px;
      font-weight: 400;
      margin: 0 0 24px;
      padding: 0 24px;
      }
      .sh4-section-title h2::after {
        content: "";
        position: absolute;
        width: 23px;
        height: 23px;
        border-top: 7px solid #D9F3E6;
        border-right: 7px solid #D9F3E6;
    }
      .sh4-section-title .yellow-btn {
        font-weight: 700;
      }
      .sh4-review-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }
      .sh4-review-star i {
        background: var(--primary-color);
        color: #fff;
        padding: 5px;
        border-radius: 5px;
      }
    .sh4-review-section {
      padding: 80px 0;
      background-size: cover;
      position: relative;
      z-index: 1;
      background-size: cover;
    }
    .sh4-review-section:after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      content: "";
      background: rgba(0, 0, 0, 0.68);
      z-index: -1;
    }
    .sh4-review-section h2 {
      color: #fff;
    font-weight: 400;
    margin-top: 24px;
    }
    .sh4-review-section h2 span {
      font-size: 32px;
    font-weight: 500;
    }
      .sh4-review-star {
        display: inline-block;
        text-align: left;
      }
      .sh4-review-star i:not(:last-child) {
        margin-right: 5px;
      }
      .sh4-review-info p {
        color: #fff;
        margin: 5px 0 0;
      }
      .sh4-trust-content-btn .btn:not(:last-child) {
        margin-right: 15px;
      }
      .sh4-trust-section {
        padding: 50px 0;
      }
      
    .sh4-split-abt-list {
      display: flex;
      justify-content: space-between;
    }
    .sh4-split-abt-list ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .sh4-split-abt-list li:nth-child(2n+1) {
      left: 44px;
    }
    .sh4-split-abt-list-img img {
      border-radius: 50%;
      margin-left: auto;
      display: flex;
    }
    .sh4-split-abt-list-img {
      position: relative;
      padding: 10px;
      z-index: 2;
      width: 50%;
    }
    .sh4-split-abt-list-img::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: 3px dashed var(--primary-color);
      border-radius: 50%;
      z-index: -4;
    }
    .sh4-split-abt-list-img::before {
      content: "";
      position: absolute;
      width: 50%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
      z-index: -1;
    }
      .sh4-trust-content p {
        color: #6D7A80;
        margin-bottom: 30px;
      }
      .sh4-trust-section .sh4-section-title {
        margin-bottom: 30px;
      }
      .sh4-trust-section h4 {
        margin: 0 0 5px;
      }
      .sh4-trust-section li {
        margin-bottom: 20px;
        padding: 15px 15px 15px 45px;
        color: #6D7A80;
        background: url(../../../assets/img/icons/tick.png) no-repeat;
        background-position: 15px center;
        border: 1px solid #CFCFCF;
        border-radius: 50px;
        position: relative;
        font-size: 18px;
        z-index: 3;
        box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07);
      }
      .sh4-trust-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
      .sh4-howwe-section {
        padding: 80px 0;
        background: url(../../../assets/img/home/works-bg.png) no-repeat;
        background-size: cover;
        position: relative;
      }
      
    .sh4-howwe-section::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.8) -17.46%, rgba(255, 255, 255, 0) 43.58%);
    }
      .sh4-card-howwe {
        text-align: center;
        padding: 0 45px;
      }
      .sh4-home-slider-howwe .sh4-swiper-wrapper {    
        background: url(../../../assets/img/icons/border.png);
        background-repeat: repeat-x;
        background-position: center 50px;
      }
      .sh4-card-howwe-img img {
        border-radius: 50%;
      }
      .sh4-card-howwe-img {
        position: relative;
        height: 195px;
        display: flex;
        justify-content: center;
        align-items: start;
      }
      .sh4-card-howwe-img span {
        position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--primary-color);
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: 500;
    top: 0;
    left: 20px;
    box-shadow: 0px 0px 0px 5px rgba(255,255,255,0.5);
      }
      .sh4-card-howwe h3 {
        margin: 15px 0;
    font-weight: 500;
    font-size: 23px;
      }
      .sh4-card-howwe-img img {
        border-radius: 50%;
        height: 195px;
        width: 195px;
    }
    .sh4-serv-section {
        padding: 70px 0;
      }
      .sh4-serv-content li {
        display: inline-block;
        background: rgba(4, 106, 56, 0.14) url(../../../assets/img/icons/tick.png) no-repeat 10px center;
        padding: 10px 10px 10px 45px;
        margin: 0 10px 10px 0;
        border-radius: 5px;
        font-size: 18px;
        color: var(--primary-color);
      }
      .sh4-serv-content h4 {
        margin-bottom: 24px;
      }
      .sh4-serv-content:not(:last-child) {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(136, 136, 136, 0.23);
      }
      .sh4-card-serv-img img {
        border-radius: 10px 10px 0 0;
        width: 100%;
      }
      .sh4-card-serv-body {
        background: #fff;
        border-radius: 0 0 10px 10px;
        padding: 24px;
      }
      .sh4-card-serv-body p {
        margin: 15px 0;
      }
      .sh4-card-serv-body .btn {
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
      .sh4-card-serv-img {
        position: relative;
      }
      .sh4-card-serv-img::after {
        content: "";
        width: 100%;
        height: 100%;
        background: url(../../../assets/img/home/img-vector.png);
        position: absolute;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
      }  
      .sh4-cate-section {
        padding: 70px 0;
      }
      .sh4-card-cate {
        position: relative;
      }
      .sh4-card-cate:after {
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.68) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        top: 0;
      }
      .sh4-card-cate img {
        width: 100%;
      }
      .sh4-card-cate-body {
        padding: 24px;
        position: absolute;
        z-index: 1;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
      }
      .sh4-card-cate-body h3 {
        margin: 10px 0;
      }
      .sh4-card-cate-body a {
        color: #fff;
        font-size: 23px;
      }
      .sh4-card-cate-body .btn {
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        padding: 0;
      }
      .sh4-card-cate-body p {
        font-weight: 600;
      }
      .sh4-safety-section {
        padding: 80px 0;
        position: relative;
        z-index: 2;
      }
      .sh4-safety-section::after {
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% - 300px);
        left: 0;
        bottom: 0;
        background: #046A38;
        background-position: bottom center;
        background-size: cover;
        z-index: -1;
      }
      .sh4-safety-info-grid {
        background: #fff;
        box-shadow: 32px 0px 77px rgba(210, 210, 210, 0.15);
        border-radius: 50px;
        overflow: hidden;
      }
      .sh4-safety-content h3 {
        margin-bottom: 15px;
        font-weight: 400;
      }
      .sh4-safety-content li {
        margin-bottom: 24px;
        border: 1px solid rgba(4, 106, 56, 0.14);
        padding: 14px;
      }
      .sh4-safety-content {
        display: flex;
        height: 100%;
        width: 75%;
      }
      .sh4-safety-list-card {
        position: relative;
        background:  rgba(255, 255, 255, 0.14);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        padding: 20px;
        color: #fff;
        margin-top: auto;
      }
      .sh4-safety-list-card h3 {
        margin-bottom: 15px;
        color: #fff;
      }
      .sh4-safety-list-card-desc h3 {
        margin: 24px 0;
      }
      .sh4-safety-list-card-desc {
        margin: 24px 0 0;
      }
      .sh4-faq-section {
        position: relative;
        padding: 80px 0;
      }
      .sh4-faq-home .accordion-item {
        margin-bottom: 15px;
        border: 0;
      }
      .sh4-faq-home .accordion-button {
        font-weight: 400;
        color: #191919;
        font-size: 20px;
        box-shadow: 0 0 0;
      }
      .sh4-faq-section .sh4-split-img-frame3::before,.sh4-faq-section .sh4-split-img-frame1::before,.sh4-faq-section .sh4-split-img-frame2::before {
        box-shadow: 0px 0px 0 10px #Fff;
      }
      .sh4-faq-section .sh4-split-img-frame3::after,.sh4-faq-section .sh4-split-img-frame1::after,.sh4-faq-section .sh4-split-img-frame2::after {
        border-color: #fff;
      }
      .sh4-faq-home .accordion-button:not(.collapsed) {
        background: #fff;
      }
      .sh4-faq-home .accordion-button::after {
        background-image: url(../../../assets/img/icons/plus.png);
      }
      .sh4-faq-home .accordion-button:not(.collapsed)::after { 
        background-image: url(../../../assets/img/icons/minus.png);
      }
      .sh4-testimonial-card {
        background: #fff;
        padding: 24px;
        border-radius: 6px;
        box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
      }
      .sh4-testimonial-card .sh4-profile-user {
        padding-right: 42px;
        background: url(../../../assets/img/icons/comment.png) no-repeat right center;
      }  
      .sh4-profile-user {
        display: flex;
        margin-bottom: 20px;
      }
      .sh4-profile-user-img img {
        width: 60px;
        height: 60px;
      }
      .sh4-profile-user-img {
        margin-right: 8px;
      }
      .sh4-profile-user-info h6 {
        margin-bottom: 5px;
      }
      .sh4-profile-user-info h6 a {
        color: #192335;
      }
      .sh4-profile-rating span {
        margin-right: 5px;
        font-size: 14px;
      }
      .sh4-testimonial-card p {
        color: #6D7A80;
      }
      .sh4-testimonial-img img {
        border-radius: 5px;
        width: 100%;
      }
      .sh4-testimonial-img {
        position: relative;
      }
      .sh4-testimonial-overlay {
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 30px;
        display: flex;
        align-items: end;
        height: 100%;
        z-index: 1;
      }
      .sh4-play-btn {
        width: 60px;
        height: 60px;
        background: #FF0059;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        margin-left: auto;
        font-size: 32px;
      }
      .sh4-testimonial-overlay p {
        width: calc(100% - 80px);
      }
      .sh4-testimonial-overlay:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.65);
        left: 0;
        top: 0;
        border-radius: 10px;
        z-index: -1;
      }
      .sh4-testimonial-section {
        position: relative;
        padding: 70px 0;
        background-image: linear-gradient(97.92deg, rgba(230, 254, 243, 0.5) 0.85%, rgba(217, 217, 242, 0.5) 101.07%);
      }
      .sh4-testimonial-card h5 {
        margin: 0 0 10px;
      }
      .sh4-mobapp-section {
        position: relative;
        padding: 70px 0;
      }
      
    .sh4-app-highlights .sh4-icon-box {
        display: flex;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      .sh4-app-highlights li {
        margin-bottom: 15px;
        background-image: linear-gradient(97.92deg, #E6FEF3 0.85%, #D9D9F2 101.07%);
        border: 1px solid rgba(4, 106, 56, 0.14);
        border-radius: 5px;
      }
      .sh4-mobapp-subtract-body {
        background: var(--secondary-color);
      }
      .sh4-app-highlights h5 {
        font-weight: 400;
        font-size: 23px;
      }
    .sh4-mobapp-content p {
        color: #354750;
      }
      .sh4-applinks {
        margin: 50px 0 0;
        display: flex;
        align-items: center;
        gap: 15px;
      }
      .sh4-qrbox {
        border: 1px solid #fff;
        padding: 10px;
        border-radius: 8px;
        display: inline-block;
      }
      .sh4-applinks a {
        display: inline-block;
      }
      .sh4-appdata {
        display: flex;
        margin: 80px 0 0;
      }
      .sh4-appdata h4 {
        color: #fff;
    margin: 0 0 10px;
    font-size: 36px;
      }
      
    .sh4-appdata li {
        flex: 1 0 0;
      }
      .sh4-appdata li span {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        width: 105px;
        height: 105px;
        border-radius: 10px;
        margin-right: 10px;
      }
      .sh4-appdata p {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
      .sh4-latestnews-section {
        padding: 70px 0;
      }
      .sh4-latestnews-card {
        background: #fff;
        border-radius: 23px;
        overflow: hidden;
      }
      .sh4-latestnews-img {
        position: relative;
      }
      .sh4-latestnews-img img {
        width: 100%;
      }
      .sh4-latestnews-img:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(3, 3, 3, 0) 6.83%, #000000 100%);
        top: 0;
        left: 0;
      }
      .sh4-latestnews-overlay {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: var(--secondary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        bottom: -25px;
        right: 25px;
        z-index: 1;
      }
      .sh4-newsdate {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: normal;
        font-size: 20px;
        font-weight: 700;
        line-height: 15px;
      }
      .sh4-newsday {
        font-weight: 400;
        font-size: 14px;
      }  
    .sh4-latestnews-info {
        padding: 24px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
      }
      .sh4-latestnews-card p {
        color: #6D7A80;
      }
      
    .sh4-latestnews-bottom {
        margin-top: auto;
      }
      .sh4-latestnews-top {
        display: flex;
        align-items: center;
      }
      .sh4-latestnews-addfav {
        margin-left: auto;
        display: flex;
        width: 35px;
        height: 35px;
        background: #fff;
        align-items: center;
        justify-content: center;
      }
      .sh4-latestnews-tag {
        background: var(--primary-color);
        color: #fff;
        font-size: 14px;
        padding: 2px 8px;
        border-radius: 5px;
      }
      .sh4-latestnews-bottom .sh4-profile-user-img img {
        width: 45px;
        height: 45px;
      }
      .sh4-latestnews-user-info {
        color: #fff;
      }
      .sh4-profile-user-info.sh4-latestnews-user-info {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      .sh4-latestnews-date {
        font-weight: 400;
        font-size: 14px;
      }
      .sh4-latestnews-bottom h6 a {
        color: #fff;
      }
      .sh4-latestnews-bottom .sh4-profile-user {
        align-items: center;
      }
      .sh4-latestnews-bottom h3 {
        color: #fff;
        font-weight: 400;
        margin-bottom: 30px;
      }
      .sh4-latestnews-arlk-count {
        padding: 24px 0 0;
        margin: 24px 0 0;
        border-top: 1px solid #F0F0F0;
        display: flex;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        align-items: center;
      }
      .sh4-arlk-count-tag {
        background: #fff;
        margin-right: 5px;
        padding: 6px;
        border-radius: 25px;
        border: 1px solid #F0F0F0;
        font-size: 14px;
        color: #192335;
      }
      .sh4-arlk-count-tag i {
        color: var(--primary-color);
      }
      .sh4-footer {
        color: #fff;
        padding: 70px 0;
    }
    .sh4-footer-sectionthree {
        padding: 40px 0 25px;
    }
    .sh4-footer h4 {
        color: #c2c2c2 !important;
    }
    .sh4-footer h4 {
        color: #fff;
        margin-bottom: 20px;
    }
    .sh4-footer-sectionthree li {
        margin-bottom: 15px;
    }
    .sh4-footer-sectionthree a {
        color: #c2c2c2 !important;
    }
    
    .sh4-footer-sectionthree a {
        color: #c2c2c2;
    }
    .sh4-footer-sectionfour {
        padding: 20px 0;
    }
    .sh4-footer-social, .sh4-logo-footer {
        margin-bottom: 20px;
    }
    .sh4-footer-social li {
        display: inline-block;
        margin-right: 20px;
    }
    .sh4-footer-sectionfour li {
        display: inline-block;
        margin: 0 10px;
    }
    .sh4-footer-sectionfour li a {
        color: #c2c2c2 !important;
    }
    .sh4-footer-sectionfour li a {
        color: #fff;
    }
    .sh4-footer-social a {
        align-items: center;
        border: 1px solid #fff;
        border-radius: 50%;
        color: #fff;
        display: flex;
        font-size: 14px;
        height: 25px;
        justify-content: center;
        width: 25px;
    }
    .sh4-footer-last {
        align-items: center;
        height: 100%;
        justify-content: center;
        margin: 24px 0 0;
    }
    .sh4-footer-last a {
        color: #fff;
        text-decoration: none;
    }
    .sh4-postcodeform .dropdown{
      position: absolute;
      margin-top: 54px;
      background: #fff;
      width: 29rem;
      box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
      border-radius: 5px;
      z-index: 99;
  } 