@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&family=Poppins:wght@700&display=swap');
.sh2-body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #191919;
    font-weight: 400;
    font-style: normal;
    background: #f8f8f8;
}
.sh2-header {
    padding: 20px 0px;
    box-shadow: 0px 4px 4px rgba(201, 201, 201, 0.25);
    background: #fff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 0.5s ease;
    position: relative;
    z-index: 2;
}
.sh2-header-nav {
    padding: 0px;
}
.sh2-logo {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
}
#site_logo {
    max-height: 95px;
    max-width: 100%;
  }
ul.sh2-header-link {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 40px;
}
ul.sh2-header-link li {
    display: inline-block;
    padding: 0 10px;
}
.sh2-header-link li a {
    font-size: 20px;
    font-weight: 700;
    color: var(--nav-color);
    text-decoration: none;
    text-decoration: none;
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -o-transition: .5s all;
    -ms-transition: .5s all;
    display: block;
}
.sh2-header-link li .nav-bar-link.active {
    color: var(--nav-color-active);
    position: relative;
  }
  .sh2-header-link li .nav-bar-link.active::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 5px;
    background: var(--primary-color);
    border-radius: 0;
    left: calc(50%);
    bottom: -8px;
    transform: translateX(-50%);
  }
.sh2-header-link li a:hover {
    color: var(--nav-color-hover);
    text-decoration: none;
}
.sh2-header-link li .sh2-cart-btn {
    align-items: center;
    display: inline-flex;
    padding: 0;
    background-color: #fff;
    transition: 250ms;
    color: #000;
    border-radius: 50px;
    width: 48px;
    justify-content: center;
    height: 48px;
    position: relative;
}
.sh2-cart-list {
    position: relative;
}
.sh2-login-link a > .sh2-color-theme {
    color: #fff !important;
    font-weight: 800;
    text-decoration: none;
}
.gr-border {
    background: #fff;
    border-radius: 10px;
  }
.gr-border-grid {
    background: #fff;
    border-radius: 10px;
}
.gr-border {
    background: linear-gradient(90deg, #16FFBD 26.23%, #FFEF46 76.17%);
    border-radius: 10px;
    padding: 4px;
}
.sh2-header-nav-box {
    padding: 15px;
    display: flex;
  }
.sh2-header-link-right {
    margin-left: auto;
  }
  .sh2-header-link-right li {
    display: inline-block;
    font-weight: 500;
    position: relative;
  }  
.sh2-header-link-right li:not(:first-child):before {
  content: "";
  position: absolute;
  width: 1px;
  height: 15px;
  background: #FFEF46;
  left: -10px;
  top: 5px;
}
  .sh2-header-box {
    border: 1px solid rgba(46, 54, 57, 0.25);
    border-radius: 10px;
    padding: 10px;
    white-space: nowrap;
  }
  .sh2-header-box a {
    color: #000;
  }
.sh2-header-link-right li:not(:first-child) {
    margin-left: 12px;
  }
  .sh2-header-link-right img {
    margin-right: 5px;
    cursor: pointer;
  }
  .sh2-header-auth a {
    color: #2E3639;
  }
  .psmodal {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.postcodeform.postcodeform-modal {
  flex-direction: column;
}
.modal-content-search {
  padding: 15px;
}
  #quickordermodal .modal-header h3,#quickordermodal2 .modal-header h3 {
    font-weight: 600;
    font-size: 16px;
}
#quickordermodal .modal-body input,#quickordermodal2 .modal-body input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 18px;
    border-radius: 5px;
}
#quickordermodal .modal-body input[type=button],#quickordermodal2 .modal-body input[type=button],
#quickordermodal .modal-body input[type=submit] {
    width: 100%;
    margin-top: 25px;
    background: var(--secondary-color);
    color: #fff;
    font-size: 16px;
    border: none;
    transition: all 300ms linear;
}
.sh2-count-blk {
    background: var(--secondary-color);
    display: inline-block;
    padding: 0px 8px;
    border-radius: 5px;
    margin-left: 5px;
  }
  .sh2-home_single_search {
    width: 100%;
    position: relative;
    z-index: 1;
    /* background: #fff url(../../../assets/img/home/banner-vbg.png) no-repeat top center; */
    overflow: hidden;
}
.sh2-banner-search-wrap {
    padding: 30px 0;
  }
  .sh2-postcodeform-container {
    margin: 0 0 60px;
}
.sh2-home_single_search h1 {
    font-size: 48px;
    font-weight: 700;
    color: #0E1017;
    margin: 24px 0;
    text-transform: uppercase;
    font-family: Poppins;
}
.sh2-home_single_search p {
    display: block;
    font-size: 20px;
    font-weight: 500;
}
.sh2-home-img-grid ul {
    display: flex;
    align-items: end;
    gap: 35px;
  }
  .sh2-home-img-grid img {
    border-radius: 35px 35px 0 0;
  }
.sh2-home-img-grid li {
    position: relative;
  }
  .sh2-home-img-grid li.active:before {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(100% + 40px);
    background: var(--secondary-color);
    z-index: -1;
    top: -10px;
    right: -10px;
    border-radius: 35px;
  }
  .sh2-home-img-grid li.active::after {
    position: absolute;
    content: "";
    width: 100%;
    height: calc(100% + 40px);
    left: -10px;
    background: var(--primary-color);
    z-index: -1;
    border-radius: 35px;
    top: 15px;
  }
  .sh2-intro-img img {
    border-radius: 20px;
    width: 100%;
  }
  .sh2-intro-img {
    margin: 0 0 20px;
  }
  .sh2-intro-content {
    padding: 0 20px;
  }
  .sh2-intro-section {
    padding: 100px 0 50px;
  }
  .sh2-intro-data-box {
    padding: 24px;
    text-align: center;
  }
  .sh2-intro-data-box li {
    position: relative;
    display: inline-block;
    min-width: 30%;
  }
  .sh2-intro-data-box li:not(:last-child) {
    margin-right: 15px;
  }
  .sh2-intro-data-box li:last-child:after {
    display: none;
  }
  .sh2-intro-data-box h2 {
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 10px;
  }
  .sh2-intro-data-box p {
    font-size: 14px;
    margin: 0;
    padding: 0 0 10px;
  }
  .sh2-intro-data-box li::after {
    background: #FFEF46;
    width: 1px;
    height: 70%;
    content: "";
    position: absolute;
    right: -5px;
    top: 0px;
  }
  
.sh2-intro-desc {
    margin: 30px 0 40px;
    text-align: center;
    font-size: 14px;
  }
  .sh2-intro-content-btn {
    text-align: center;
    margin: 0 0 20px;
  }
  .sh2-intro-content-btn p {
    font-weight: 500;
    color: #0E1017;
    margin: 35px auto 0;
    max-width: 250px;
  }
  .sh2-intro-content-btn .btn {
    background: #16FFBD;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
  }
  .sh2-intro-content-btn .btn:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #FFEF46;
    animation: pulse-border 1500ms ease-out infinite;
  }
  .sh2-intro-content-btn .btn::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 1px solid #FFEF46;
    animation: pulse-border 1500ms ease-out infinite;
  }
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
  
.sh2-intro-right-grid {
    height: 100%;
  }
  .sh2-intro-points-box {
    padding: 25px;
    text-align: center;
  }
  .sh2-intro-points-box h5 {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
  }
  .sh2-intro-points-box img {
    margin: 0 0 10px;
  }
  .sh2-intro-points-box .gr-border {
    background: red;
  }
  .sh2-intro-right-grid .gr-border {
    margin-top: 24px;
    border: 1px solid #D7E3E3;
    transition: all 0.3s;
    background: #fff;
  }
  .sh2-intro-right-grid .gr-border:hover {
    background: linear-gradient(90deg, #16FFBD 26.23%, #FFEF46 76.17%);
  }
  
.sh2-review-grid h2 {
    font-weight: 700;
    font-size: 24px;
    font-family: Poppins;
    margin: 0 0 15px;
  }
  .sh2-review-grid {
    position: relative;
    z-index: 1;
  }
  .sh2-review-grid h2 span {
    position: relative;
  }
  .sh2-review-stars {
    color: #FFAD00;
    margin: 0 0 15px;
  }
  .sh2-review-grid p {
    font-size: 20px;
    font-weight: 500;
  }
  .sh2-section-card {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
  }
  .sh2-section-title {
    text-align: center;
    margin: 0 0 50px;
    text-transform: uppercase;
  }
  .sh2-section-title2 {
    text-align: center;
    margin: 0 0 40px;
    text-transform: uppercase;
  }
  .sh2-section-title p {
    font-size: 20px;
    font-weight: 500;
  }
.sh2-section-title h2{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 700;
    margin: 15px 0;
  }
  .sh2-section-title2 h2{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 700;
    margin: 0;
  }
  .sh2-section-title span {
    color: var(--primary-color);
  }

  .sh2-highlight-row {
    background: #fff;
    border-radius: 20px;
    margin: 0 0 50px;
    padding: 24px;
  }
  .sh2-highlight-box {
    padding: 25px;
    border-radius: 10px;
  }
  .sh2-highlight-bg1 {
    background: #FFFFF2;
  }
  .sh2-highlight-bg2 {
    background: #F0FFFB;
  }
  .sh2-highlight-img img {
    border-radius: 20px;
    max-width: 100%;
    max-height: 450px;  
    width: 100%;
  }
  .sh2-highlight-img {
    position: relative;
    margin: 0 0 30px;
    min-height: 415px;
    text-align: center;
  }
  .sh2-highlight-overlay {
    position: absolute;
    bottom: 0;
    background: #fff;
    border-radius: 20px;
    margin: 30px;
    width: 300px;
    right: 0;
    padding: 30px;
  }
  .sh2-highlight-overlay img {
    width: auto;
    height: auto;
    margin-right: 10px;
  }
  .sh2-highlight-overlay h3 {
    font-size: 18px;
    font-weight: 600;
    color: #191919;
    margin: 0 0 20px;
  }
  .sh2-highlight-overlay p {
    color: #818181;
    font-size: 15px;
    margin: 0 0 20px;
  }
  .sh2-highlight-content p {
    font-size: 14px;
    margin: 0 0 40px;
  }
  .sh2-highlight-overlay li {
    display: flex;
  }
  .sh2-highlight-overlay h6 {
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
  }
  .sh2-highlight-overlay li:not(:last-child) {
    margin-bottom: 10px;
  }
  .sh2-highlight-content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .sh2-highlight-content li {
    width: calc(50% - 15px);
    display: flex;
    align-items: center;
    color: #818181;
  }
  .sh2-highlight-content ul {
    display: flex;
    flex-flow: wrap;
    gap: 15px;
  }
  .sh2-highlight-content img {
    margin-right: 10px;
  }
  .sh2-postcodeform {
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid rgba(46, 54, 57, 0.25);
    padding: 10px;
    border-radius: 10px;
}
.sh2-postcodeform input[type=text] {
    padding: 10px 15px;
    border: 0;
    width: 100%;
    font-weight: 700;
    outline: 0;
}
.sh2-postcodeform .btn a{
    text-decoration: none;
    color: #fff;
}
.sh2-postcodeform input[type=submit],.sh2-postcodeform .btn {
  padding: 15px;
  font-size: 14px;
  border: 0;
  background-color: var(--secondary-color);
  flex: 0 0 auto;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border-radius: 10px;
  color: #2E3639;
}
.sh2-postcodeform .dropdown{
  position: absolute;
  margin-top: 62px;
  background: #fff;
  width: 29rem;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
  border-radius: 5px;
  z-index: 99;
}
.sh2-postcodeform-modal.sh2-postcodeform .dropdown, .notcovered .sh2-postcodeform .dropdown{
  margin-top: 55px;
}
.sh2-postcodeform .dropdown:empty {
  border: none !important;
}
.sh2-postcodeform.sh2-postcodeform-modal {
  flex-direction: column;
}
  .sh2-services-slider-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F0FFFB;
    position: relative;
    border-radius: 20px;
    transition: all 0.5s;
    padding: 30px;
    text-align: center;
  }
  .sh2-services-slider-grid h5 {
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0;
  }
  .sh2-services-section {
    padding: 50px 0;
  }
  .sh2-services-slider-grid li:not(:last-child) {
    margin: 0 0 15px;
  }
  .sh2-services-slider-grid li {
    font-size: 15px;
    color: #818181;
  } 
.sh2-services-slider-grid.sh2-services-slider-grid-lbl {
    background: #F8F8F8;
    border-radius: 0;
  }
  .sh2-services-slider-grid.sh2-services-slider-grid-lbl img,.sh2-services-slider-grid.sh2-services-slider-grid-lbl h5 {
    visibility: hidden;
  }
  .sh2-services-slider-grid.sh2-services-slider-grid-lbl li {
    color: #000000;
  }
  .sh2-faq-section {
    position: relative;
    padding: 50px 0;
  }
  .sh2-faq-subtract-top:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    top: 0;
    background: url(../../../assets/img/home/faqsubtract.png);
    background-size: 100% 200px;
    background-repeat: no-repeat;
    z-index: 1
  }
  .sh2-faq-subtract-bottom:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    bottom: -1px;
    background: url(../../../assets/img/home/faqsubtract-bottom.png);
    background-size: 100% 50px;
    background-repeat: no-repeat;
    z-index: 1;
    background-position: bottom;
  }
  .sh2-faq-home {
    width: 100%;
  }
.sh2-faq-content {
    position: relative;
    z-index: 2;
  }
  .sh2-faq-home .accordion-item {
    margin-bottom: 15px;
    border: 1px solid #D7E3E3;
    border-radius: 10px;
    overflow: hidden;
  }
  .sh2-faq-home .accordion-button {
    font-weight: 700;
    color: #191919;
    font-size: 18px;
    box-shadow: 0 0 0;
  }
  .sh2-faq-home .accordion-button:not(.collapsed) {
    background: #fff;
  }
  .sh2-faq-home .accordion-button::after {
    background-image: url(../../../assets/img/icons/plus.png);
  }
  .sh2-faq-home .accordion-button:not(.collapsed)::after { 
    background-image: url(../../../assets/img/icons/minus.png);
  }
  .sh2-testimo-slider .swiper-button-prev {
    bottom: 0;
    margin-top: auto;
    left: calc(50% - 30px);
  }
  .sh2-testimo-slider .swiper-button-next {
    bottom: 0;
    margin-top: auto;
    left: calc(50% + 30px);
  }
  .sh2-testimo-slider .swiper-button-next::after,.sh2-testimo-slider .swiper-button-prev::after { 
    color: #0E1017;
  }
  .sh2-testimo-slider .swiper {
    padding-bottom: 70px
  }
  .sh2-latestnews-img img {
    width: 100%;
    height: 450px;
  }
  .sh2-testimo-slider .swiper-button-next,.sh2-testimo-slider .swiper-button-prev {
    width: 40px;
  }
  .sh2-testimonial-card {
    background: #fff;
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #D7E3E3;
    position: relative;
    margin-top: 43px;
  }
  .sh2-testimonial-card::before {
    content: url(../../../assets/img/icons/comment.svg);
    position: absolute;
    top: -45px;
    right: 25px;
  }
  .sh2-profile-user {
    display: flex;
    margin-bottom: 20px;
  }
  .sh2-profile-user-img img {
    border-radius: 5px;
    width: 50px;
    height: 50px;
  }
  .sh2-profile-user-img {
    margin-right: 8px;
  }
  .sh2-profile-user-info h6 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #191919;
  }
  .sh2-profile-user-info h6 a {
    color: #191919;
  }
  .sh2-testimonial-card p {
    color: #818181;
    font-size: 15px;
  }
  .sh2-testimonial-img img {
    border-radius: 5px;
  }
  .sh2-testimonial-img {
    position: relative;
  }
  .sh2-testimonial-overlay {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 30px;
    display: flex;
    align-items: end;
    height: 100%;
    z-index: 1;
  }
  .sh2-play-btn {
    width: 60px;
    height: 60px;
    background: #FF0059;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    margin-left: auto;
    font-size: 32px;
  }
  .sh2-testimonial-overlay p {
    width: calc(100% - 80px);
  }
  .sh2-testimonial-overlay:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    left: 0;
    top: 0;
    border-radius: 10px;
    z-index: -1;
  }
  .sh2-testimonial-section {
    position: relative;
    padding: 50px 0;
    background: #fff;
  }
  .sh2-testimo-slider .swiper-button-next::after,.sh2-testimo-slider .swiper-button-prev::after {
    font-size: 13px !important;
    border: 2px solid;
    border-radius: 10px;
    width: 52px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323F52;
}
.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 13px !important;
  border: 2px solid;
  border-radius: 10px;
  width: 52px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #323F52;
}
.slider-adrs .swiper-button-next::after, .slider-adrs .swiper-button-prev::after {
  color: #333;
}
.slider-adrs.swiper {
  z-index: 0;
}
.adrsselectdd > div {
  flex: 1 0;
}
.sh2-latestnews-section {
    padding: 50px 0;
    background: #F0FFFB;
}
  .sh2-latestnews-card {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
  }
  .sh2-latestnews-img {
    position: relative;
  }
  .sh2-latestnews-img:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 203, 0, 0.25) 0%, rgba(255, 0, 89, 0.25) 58.84%);
    top: 0;
    left: 0;
  }
  .sh2-latestnews-overlay {
    position: absolute;
    background: linear-gradient(90deg, #16FFBD 0%, #FFEF46 108.52%);
    bottom: 0;
    right: 0;
    z-index: 1;
    color: #191919;
    padding: 10px;
    font-size: 15px;
  }
  .sh2-latestnews-info h6 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .sh2-newsdate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: normal;
    font-size: 20px;
    font-weight: 700;
    line-height: 15px;
  }
  .sh2-newsday {
    font-weight: 400;
    font-size: 14px;
  }  
.sh2-latestnews-info {
    padding: 24px 24px 24px 0;
  }
  
.sh2-latestnews-date {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: right;
    padding: 15px 15px 80px 15px;
    background: linear-gradient(90deg, #16FFBD 0%, #FFEF46 108.52%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 15px;
  }
  .sh2-latestnews-card p {
    color: #6D7A80;
  }
  .sh2-loademore-card {
    text-align: center;
    margin: 50px 0 0;
  }
  .gr-btn {
    position: relative;
    z-index: 1;
    padding: 5px 24px;
    text-transform: uppercase;
    font-weight: 500;
    height: 44px;
  }
  .gr-btn:after {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #fff;
    content: "";
    left: 2px;
    z-index: -1;
    top: 2px;
    border-radius: 10px;
  }
  .gr-btn:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #16FFBD 26.23%, #FFEF46 76.17%);
    left: 0;
    top: 0;
    z-index: -2;
    border-radius: 10px;
  }
  .sh2-mobapp-section {
    position: relative;
    padding: 50px 0;
  }
  .sh2-mobapp-subtract-top:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    top: 0;
    background: url(../../../assets/img/home/mobsubtract-top.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: -1;
  }
  .sh2-mobapp-subtract-bottom:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    bottom: 0;
    background: url(../../../assets/img/home/mobsubtract-top.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: bottom;
  }
.sh2-mobapp-content p {
    color:#818181;
    font-size: 15px;
  }
  .sh2-applinks {
    margin: 20px 0 0;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
  }
  .sh2-qrbox {
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 8px;
    display: inline-block;
  }
  .sh2-applinks a {
    display: inline-block;
  }
  .sh2-appdata {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
  }
  .sh2-appdata h4 {
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0;
  }
  .sh2-mobapp-section .sh2-section-title2 {
    margin-bottom: 15px;
    text-align: left;
  }  
.sh2-mobapp-content {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding: 50px;
  }
  
.sh2-mobapp-subtract-body {
    background: #fff;
    border-radius: 20px;
  }
  .footer-socialli li {
    display: inline-block;
    margin-right: 15px;
  }
  .sh2-footer {
    background: #fff url(../../../assets/img/home/footer-bg-temp-2.png) no-repeat;
    padding-top: 40px;
}
.sh2-footer-sectiontwo {
    padding: 25px 0;
}
.sh2-footer-sectionthree {
    padding: 40px 0 25px;
}
.sh2-footer-sectionfour {
    border-top: 1px solid #e9e9e9;
    padding: 20px 0;
}
.sh2-social-links {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.sh2-footer-social ul li {
    display: inline-block;
    padding-right: 5px;
}
.sh2-footer-social ul li a {
    align-items: center;
    color: #818181;
    display: flex;
    font-size: 15px;
    padding-right: 0;
}
.sh2-footer-social ul li a i {
    margin-right: 5px;
}
.sh2-footer h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.sh2-footer-sectionthree li {
    margin-bottom: 15px;
}
.sh2-footer-sectionthree a {
    color: #6d7a80;
}
.sh2-footer-last a {
    color: #333;
    text-decoration: none;
}
.sh2-postcodeform-container .adrsgriddv {
  background: rgba(0,0,0,0.2);
}
@media only screen and (max-width: 767px) { 
  .sh2-intro-data-box li::after {
    display: none;
}

.sh2-highlight-content li {
    width: 100%;
}
  .sh2-intro-section {
    padding: 20px 0 0;
}

.sh2-highlight-box.sh2-highlight-bg1 {
    position: relative;
    padding: 0;
}

.sh2-highlight-overlay {
    position: relative;
    margin: 0;
    left: 0;
    padding: 10px 0;
    text-align: left;
}
.sh2-highlight-box.sh2-highlight-bg2 {
  background: #fff;
  padding: 10px 0;
}

.sh2-services-section {
  padding: 0 0 20px;
}

.sh2-section-title2 h2,.sh2-section-title h2 {
  font-size: 24px;
}

.sh2-services-slider-grid.sh2-services-slider-grid-lbl {
  display: none;
}
.sh2-testimo-slider .swiper {
  padding-bottom: 50px;
}
.sh2-services-slider-grid {
  margin: 0 0 20px;
}
.sh2-latestnews-section {
  padding: 20px 0; 
}
.sh2-latestnews-card {
  margin: 0 0 20px;
}
.sh2-loademore-card {
  margin: 0 0;
}
.sh2-home_single_search h1 {
  font-size: 24px;
}
.sh2-postcodeform {
  flex-direction: column;
}
.sh2-home_single_search {
  overflow: visible;
  padding: 40px 0;
}
.sh2-logo #site_logo {
  max-height: 30px;
  border-radius: 0;
}
.sh2-logo #sidebarCollapse img,.sh2-logo .mob-user img {
  filter: brightness(1) invert(0);
}
.sh2-postcodeform-container .adrsselectdd.adrsselectddinp {
  flex-direction: column;
}
.sh2-safety-section {
  padding: 40px 0;
}
.sh2-testimonial-section,.sh2-cate-section {
  padding-bottom: 0;
  padding-top: 20px;
}
.sh2-mobapp-section,.sh2-footer,.sh2-serv-section {
  padding-top: 20px;
}
.sh2-safety-list-card {
  margin-top: 15px;
  margin-bottom: 5px;
}
.sh2-safety-content {
  margin-top: 20px;
}
.sh2-howwe-section,.sh2-review-section {
  padding: 20px 0;
}
.sh2-banner-search-wrap {
  padding: 0;
}
.sh2-home_single_search .sh2-postcodeform input[type=submit],
 .sh2-home_single_search .sh2-postcodeform .btn {
  padding: 10px;
}
.easelaundry-wrapper {
  padding: 20px 0;
}
.section-app {
  padding: 0 0 20px;
}

.sh2-home-img-grid ul {
  gap: 5px;
}
.sh2-logo {
  width: 100%;
}
.sh2-home_single_search h1 {
  font-size: 26px;
}
}
@media only screen and (max-width:575px){ 

}
@media only screen and (max-width:479px){ 

}