.sh1-body {
    background: #fff;
    color: #2e3639;
    font-family: Nunito,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.banner-tag img {
  margin-right: 10px;
}
.sh1-header {
    background: #fff;
    box-shadow: 0 4px 4px hsla(0,0%,79%,.25);
    padding: 20px 0;
    transition: all .5s ease;
}
.sh1-header-nav {
    padding: 0;
}
.sh1-logo {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
}
.sh1-logo a {
    display: inline-block;
}
.sh1-header-nav .dropdown-menu li a {
    font-weight: 400;
    padding: 10px 15px;
}
ul.sh1-header-link {
    align-items: center;
    display: flex;
    height: 100%;
}
ul.sh1-header-link li {
    display: inline-block;
    padding: 0 10px;
}
.sh1-header-link li a {
    font-size: 20px;
    font-weight: 700;
    color: var(--nav-color);
    text-decoration: none;
    text-decoration: none;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    display: block;
  }
  .sh1-header-link li .sh1-nav-bar-link.active {
    color: var(--nav-color-active);
    position: relative;
  }
  .sh1-header-link li .sh1-nav-bar-link.active::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    background: var(--nav-color-active);
    border-radius: 50%;
    left: -10px;
    top: 9px;
  }
  .sh1-header-link li a:hover {
    color: var(--nav-color-hover);
    text-decoration: none;
  }
  .sh1-header-link li .sh1-cart-btn {
    align-items: center;
    display: inline-flex;
    padding: 0;
    background-color: #fff;
    transition: 250ms;
    color: #000;
    border-radius: 50px;
    width: 48px;
    justify-content: center;
    height: 48px;
    position: relative;
  }
  .sh1-cart-list {
    position: relative;
  }
  .sh1-login-link a > .sh1-color-theme {
    color: #fff !important;
    font-weight: 800;
    text-decoration: none;
  }
  .sh1-header-link-right {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-left: auto;
}
.sh1-header-link-right li {
    display: inline-block;
    font-weight: 500;
}
.sh1-header-link-right a {
    color: #2e3639;
}
.sh1-header-link-right li:not(:first-child) {
    margin-left: 12px;
}
.sh1-header-box {
    border: 1px solid rgba(46,54,57,.25);
    border-radius: 10px;
    padding: 10px;
}
.sh1-header-link-right img {
    margin-right: 5px;
}
.sh1-cart-icon, .sh1-count-blk {
    border-radius: 5px;
}
.sh1-count-blk {
    background: #ffcb00;
    background: var(--secondary-color);
    display: inline-block;
    margin-left: 5px;
    padding: 0 10px;
}
.sh1-header-auth a {
    color: #2e3639;
}
.sh1-home_single_search {
    /* background: url("../../../assets/img/home_page_single_search.jpg") no-repeat top center; */
    background-size: cover!important;
    position: relative;
    width: 100%;
    z-index: 1;
}
.sh1-banner-search-wrap {
    padding: 150px 0;
}
.sh1-banner-tag {
    background: #fff;
    border-radius: 10px;
    display: inline-block;
    margin: 0 0 40px;
    padding: 3px 10px;
}
.sh1-home_single_search h1 {
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 0;
}
.sh1-home_single_search p {
    display: inline-block;
    font-size: 20px;
}
.sh1-postvalidation {
    background: #fff;
}
.sh1-postcodeform input[type=text] {
    border: 0;
    font-weight: 700;
    outline: 0;
    padding: 10px 15px;
    width: 100%;
}
.sh1-postcodeform input[type="submit"],
.sh1-postcodeform .btn {
  padding: 15px;
  font-size: 14px;
  border: 0;
  background-color: var(--secondary-color);
  flex: 0 0 auto;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border-radius: 10px;
  color: #2e3639;
}
.sh1-postcodeform {
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid rgba(46, 54, 57, 0.25);
    padding: 10px;
    border-radius: 10px;
  }
  .sh1-postcodeform input[type="text"] {
    padding: 10px 15px;
    border: 0;
    width: 100%;
    font-weight: 700;
    outline: 0;
  }
  .sh1-postcodeform .btn a {
    text-decoration: none;
    color: #fff;
  }
  .sh1-postcodeform .dropdown {
    position: absolute;
    margin-top: 62px;
    background: #fff;
    width: 29rem;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 99;
  }
  .postcodeform-modal.postcodeform .dropdown,
  .notcovered .postcodeform .dropdown {
    margin-top: 55px;
  }
  .sh1-postcodeform .dropdown:empty {
    border: none !important;
  }
  .dropdown-row {
    cursor: pointer;
    text-align: start;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 18px;
    position: relative;
  }
  .dropdown-row i {
    margin: 0 10px 0 0;
    color: #a7c1d1;
  }
  .dropdown-row:not(:last-child) {
    border-bottom: 1px solid #dce0e3;
  }
  .dropdown-row:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
  .dropdown-row:hover i {
    color: #fff;
  }

  .sh1-trust-section {
    padding: 50px 0;
}
.sh1-trust-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.sh1-trust-content p, .sh1-trust-section .sh1-section-title {
    margin-bottom: 30px;
}
.sh1-trust-section h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 25px 0;
}
.sh1-trust-section li {
    margin-bottom: 20px;
    padding-left: 30px;
    color: #6d7a80;
    background: url(../../../assets/img/icons/tick.svg) no-repeat;
    background-position: left center;
  }
.sh1-priority-section {
    padding: 50px 0;
}
.sh1-priority-img img {
    border-radius: 8px;
    transition: all .5s ease;
}
.sh1-priority-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 2px 3px rgba(0,0,0,.1);
    padding: 25px;
}
.sh1-priority-card h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0 15px;
}
.sh1-priority-card p {
    color: #6d7a80;
    margin: 0;
}
.sh1-priority-img img:hover {
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    transition: ease all 0.5s;
    transform: scale(1.1) rotate(3deg);
    -webkit-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
  }

  .sh1-mobapp-section {
    position: relative;
    padding: 70px 0;
  }
  .sh1-mobapp-subtract-top:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    top: 0;
    background: url(../../../assets/img/home/mobsubtract-top.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: -1;
    display: none;
  }
  .sh1-mobapp-subtract-bottom:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    bottom: 0;
    background: url(../../../assets/img/home/mobsubtract-top.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: bottom;
    display: none;
  }
  .sh1-mobapp-subtract-body {
    background: var(--secondary-color);
    padding: 70px 0;
  }
  
  .sh1-mobapp-content p {
    color: #354750;
  }
  .sh1-applinks {
    margin: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .sh1-qrbox {
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 8px;
    display: inline-block;
  }
  .sh1-applinks a {
    display: inline-block;
  }
  .sh1-appdata {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
  }
  .sh1-appdata h4 {
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0;
  }
  .sh1-mobapp-section .sh1-section-title {
    margin-bottom: 30px;
  }
  .sh1-mobapp-content {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }

  .sh1-subscriptioninfo-box {
    border-radius: 10px;
    display: flex;
    height: 100%;
  }
  .sh1-subscriptioninfo-bg1 {
    background: #ffcfe0;
  }
  .sh1-subscriptioninfo-bg2 {
    background: linear-gradient(0deg, #fff6d2, #fff6d2),
      linear-gradient(180deg, #ffcb00 0%, #ffd1e1 100%);
  }
  .sh1-subscriptioninfo-img {
    display: flex;
    align-items: end;
    height: 100%;
    justify-content: end;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  .sh1-subscriptioninfo-img:before {
    width: 400px;
    height: 400px;
    content: "";
    position: absolute;
    background: #fff;
    border-radius: 50%;
    left: 5%;
    bottom: -50%;
    z-index: -1;
  }
  .sh1-subscriptioninfo-bg1 .sh1-subscriptioninfo-img::before {
    background: #fff6d2;
  }
  .sh1-subscriptioninfo-bg2 .sh1-subscriptioninfo-img::before {
    background: #ffcfe0;
  }
  
  .sh1-subscriptioninfo-content {
    padding: 80px 35px 20px;
    text-align: center;
  }
  .sh1-subscriptioninfo-content h4 {
    margin: 20px 0;
    font-size: 24px;
    font-weight: 700;
  }
  .sh1-subscriptioninfo-content p {
    color: #6d7a80;
  }
  .sh1-subscriptioninfo-content .btn {
    margin-top: 15px;
  }
  
  .sh1-services-slider-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    position: relative;
    border-radius: 10px;
    height: calc(450px - 60px);
    border-radius: 10px;
    transition: all 0.5s;
    padding: 0 20px;
    text-align: center;
    transition: all 0.5s;
  }
  .sh1-services-slider-grid:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        0deg,
        rgba(255, 0, 89, 0.76),
        rgba(255, 0, 89, 0.76)
      )
        no-repeat;
    z-index: -1;
    border-radius: 10px;
  }
  .sh1-services-slider-grid:hover {
    background: transparent;
  }
  .sh1-services-slider-grid:hover * {
    color: #fff;
  }
  
  .swiper-slide.swiper-slide-active .sh1-btnslider,
  .swiper-slide:hover .sh1-btnslider {
    visibility: visible;
    bottom: -10px;
  }
  .sh1-services-slider-grid h5 {
    font-size: 24px;
    font-weight: 700;
    margin: 15px 0;
  }
  .sh1-services-slider-grid p {
    color: #6d7a80;
  }
  .sh1-btnslider {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    visibility: hidden;
    position: relative;
    bottom: -30px;
  }
  .sh1-btnslider i {
    margin-left: 15px;
  }
  .sh1-services-section {
    padding: 50px 0 60px;
    background: #f8f8f8;
    margin: 50px 0 0;
  }
  
  .sh1-services-slider .swiper-button-prev {
    bottom: 0;
    margin-top: auto;
    left: 222px;
  }
  .sh1-services-slider .swiper-button-next {
    bottom: 0;
    margin-top: auto;
    left: 260px;
  }
  .sh1-services-slider .swiper-button-next::after,
  .sh1-services-slider .swiper-button-prev::after {
    color: #ffcb00;
  }
  .sh1-testimonial-card {
    background: #fff;
    padding: 24px;
    border-radius: 10px;
    border: 1px solid rgba(46, 54, 57, 0.25);
  }
  .sh1-testi-grid {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    justify-content: center;
  }
  .sh1-testimonial-card, .sh1-testimonial-img {
    flex: calc(33.3% - 15px) 0 0;
    border-radius: 10px;
  }
  .sh1-testimonial-card p {
    color: #6d7a80;
  }
  .sh1-testimonial-img img {
    border-radius: 5px;
    width: 100%;
    height: 215px;
  }
  .sh1-testimonial-img {
    position: relative;
  }
  .sh1-testimonial-overlay {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 30px;
    display: flex;
    align-items: end;
    height: 100%;
    z-index: 1;
  }
  .sh1-play-btn {
    width: 60px;
    height: 60px;
    background: #ff0059;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    margin-left: auto;
    font-size: 32px;
  }
  .sh1-testimonial-overlay p {
    width: calc(100% - 80px);
  }
  .sh1-testimonial-overlay:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    left: 0;
    top: 0;
    border-radius: 10px;
    z-index: -1;
  }
  .sh1-testimonial-section {
    position: relative;
    padding: 80px 0 0;
  }
  .sh1-testimonial-subtract-top:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    top: 0;
    background: url(../../../assets/img/home/testimonialsubtract.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: -1;
  }
  .sh1-testimonial-section::after {
    position: absolute;
    content: "";
    width: 100%;
    background: #fff;
    height: calc(100% - 200px);
    bottom: 0;
    z-index: -1;
  }
  .sh1-faq-section {
    position: relative;
    padding: 100px 0 0;
  }
  .sh1-faq-subtract-top:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    top: 0;
    background: url(../../../assets/img/home/faqsubtract.png);
    background-size: 100% 200px;
    background-repeat: no-repeat;
    z-index: 1;
  }
  .sh1-faq-subtract-bottom:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 100%;
    bottom: -1px;
    background: url(../../../assets/img/home/faqsubtract-bottom.png);
    background-size: 100% 50px;
    background-repeat: no-repeat;
    z-index: 1;
    background-position: bottom;
  }
  .sh1-faq-home {
    margin-top: 40px;
    width: 100%;
  }
  .sh1-faq-content {
    position: relative;
    z-index: 2;
    margin-top: 150px;
  }
  .sh1-faq-home .accordion-item {
    margin-bottom: 15px;
    border: 0;
  }
  .sh1-faq-home .accordion-button {
    font-weight: 700;
    color: #191919;
    font-size: 18px;
    box-shadow: 0 0 0;
  }
  .sh1-faq-home .accordion-button:not(.collapsed) {
    background: #fff;
  }
  .sh1-faq-home .accordion-button::after {
    background-image: url(../../../assets/img/icons/plus.png);
  }
  .sh1-faq-home .accordion-button:not(.collapsed)::after {
    background-image: url(../../../assets/img/icons/minus.png);
  }
  .sh1-mobapp-img {
    position: relative;
  }
  .sh1-mobapp-img-overlay {
    position: absolute;
    bottom: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 70px;
    background: rgba(0, 0, 0, 0.67);
    padding: 40px;
    text-align: center;
    border-radius: 20px;
    backdrop-filter: blur(9px);
    z-index: 2;
  }
  .sh1-mobapp-img-overlay p {
    color: #ffcb00;
    font-weight: 600;
    font-size: 24px;
  }
  .sh1-mobapp-img-overlay h4 {
    font-weight: 700;
    color: #fff;
    font-size: 40px;
    margin-bottom: 40px;
  }
  .sh1-mobapp-img-overlay .btn {
    font-size: 14px;
  }
  .sh1-mobapp-img-overlay i {
    font-size: 12px;
    margin-left: 10px;
  }
.sh1-profile-user {
    display: flex;
    margin-bottom: 20px;
  }
.sh1-profile-user-img img {
    border-radius: 5px;
    width: 50px;
    min-width: 50px;
    height: 50px;
}
.sh1-profile-user-img {
    margin-right: 8px;
}
.sh1-profile-user-info h6 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 700;
}
.sh1-profile-user-info h6 a {
    color: #191919;
}
.sh1-latestnews-card {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
  }
.sh1-latestnews-img {
    position: relative;
  }
.sh1-latestnews-img img {
    width: 100%;
  }
.sh1-latestnews-img:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 203, 0, 0.25) 0%,
      rgba(255, 0, 89, 0.25) 58.84%
    );
    top: 0;
    left: 0;
  }
.sh1-latestnews-overlay {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: -25px;
    right: 25px;
    z-index: 1;
  }
.sh1-newsdate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: normal;
    font-size: 20px;
    font-weight: 700;
    line-height: 15px;
  }
.sh1-newsday {
    font-weight: 400;
    font-size: 14px;
  }
.sh1-latestnews-info {
    padding: 24px;
    border: 1px solid rgba(46, 54, 57, 0.25);
    border-radius: 0 0 10px 10px;
  }
.sh1-latestnews-card p {
    color: #6d7a80;
  }
  .sh1-section-title {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 50px;
  }
  .sh1-section-title span {
    color: var(--primary-color);
  }
  .sh1-latestnews-section {
    padding: 50px 0 100px;
  }
  .sh1-footer {
    background-color: #fff;
    color: #6d7a80;
    padding-top: 40px;
}
.sh1-footer-sectionthree {
    padding: 40px 0 25px;
}
.sh1-footer h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
.sh1-footer-sectionthree li {
    margin-bottom: 15px;
}
.sh1-footer-sectionthree a {
    color: #6d7a80;
}
.sh1-footer-area-links li:not(:last-child) {
    border-right: 1px solid #e3e3e3;
    margin-bottom: 20px;
    margin-right: 15px;
    padding-right: 15px;
}
.sh1-footer-area-links li {
    display: inline-block;
}
.sh1-footer-social {
    margin-top: 10px;
}
.sh1-footer-social ul {
    text-align: right;
}
.sh1-footer-social ul li:not(:last-child) {
    margin-right: 10px;
}
.sh1-footer-social ul li {
    display: inline-block;
    padding-right: 5px;
}
.sh1-footer-social ul li a {
    padding-right: 0;
}
.sh1-footer-social i {
    color: #fff;
    font-size: 21px;
}
.sh1-footer-sectionfour {
    border-top: 1px solid #e9e9e9;
    padding: 20px 0;
}
.sh1-footer-last {
    align-items: center;
    display: flex;
    height: 100%;
}
.sh1-footer-last a {
    color: #fff;
    text-decoration: none;
}
@media only screen and (max-width:991px){ 

}
@media only screen and (max-width: 767px) { 
  .sh1-testimonial-card, .sh1-testimonial-img {
    flex: calc(100% - 15px) 0;
}
.sh1-mobapp-img-overlay {
  top: auto;
  bottom: 0;
  padding: 10px;
}
.sh1-mobapp-img-overlay p {
  font-size: 16px;
}
.sh1-mobapp-img-overlay h4 {
  font-size: 18px;
}
  .sh1-trust-content p,
  .sh1-trust-section .sh1-section-title,
  .sh1-section-title,
  .sh1-mobapp-section .sh1-section-title {
      margin-bottom: 10px;
      font-size: 20px;
  }
  .sh1-subscriptioninfo-box {
    margin-top: 15px;
    height: auto;
}
.sh1-footer-sectionthree {
  padding: 0;
}
  .sh1-priority-img {
    margin-top: 10px;
}
  .sh1-trust-section {
    padding-bottom: 0;
  }
  .sh1-priority-section,.sh1-mobapp-subtract-body {
    padding: 20px 0;
  }
  .sh1-banner-tag {
    font-size: 14px;
  }
  .sh1-home_single_search h1 {
    font-size: 24px;
  }
  .sh1-postcodeform {
    flex-direction: column;
  }
  .sh1-home_single_search {
    overflow: visible;
    padding: 40px 0;
  }
  .sh1-logo #site_logo {
    max-height: 30px;
    border-radius: 0;
  }
  .sh1-logo #sidebarCollapse img,.sh1-logo .mob-user img {
    filter: brightness(1) invert(0);
  }
  .sh1-postcodeform-container .adrsselectdd.adrsselectddinp {
    flex-direction: column;
  }
  .sh1-safety-section {
    padding: 40px 0;
  }
  .sh1-testimonial-section,.sh1-cate-section {
    padding-bottom: 0;
    padding-top: 20px;
  }
  .sh1-mobapp-section,.sh1-footer,.sh1-serv-section {
    padding-top: 20px;
  }
  .sh1-safety-list-card {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .sh1-safety-content {
    margin-top: 20px;
  }
  .sh1-howwe-section,.sh1-review-section {
    padding: 20px 0;
  }
  .sh1-banner-search-wrap {
    padding: 0;
  }
  .sh1-home_single_search .sh1-postcodeform input[type=submit],
   .sh1-home_single_search .sh1-postcodeform .btn {
    padding: 10px;
  }
  .easelaundry-wrapper {
    padding: 20px 0;
  }
  .section-app {
    padding: 0 0 20px;
  }
}
@media only screen and (max-width:575px){ 

}
@media only screen and (max-width:479px){ 

}